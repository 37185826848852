import React from "react";
import '../../Common.css';
import './MyProfilePage.css';
// api and other functions
import { useContext,useEffect,useState } from "react";
import { AuthContext } from "../../services/AuthContext";
import { PersonalProfileAPI,UpdateProfileAPI } from "../../services/Api";
import { useNavigate } from "react-router-dom";
// Components
import NavBar from "../../components/NavBar/NavBar";
import NavBarMainAR from "../../components/NavBarMain/NavBarMainAR";
import FooterAR from "../../components/Footer/FooterAR";
import InputTextField from "../../components/InputFields/InputTextField/InputTextField";
import PrimaryMutedButton from "../../components/Buttons/PrimaryMutedButton/PrimaryMutedButton";
import PrimarySolidButton from "../../components/Buttons/PrimarySolidButton/PrimarySolidButton";
import UserIconAndName from "../../components/UserIconAndName/UserIconAndName";
import ForgotPassword2AR from "../../components/PopUps/ForgotPassword/ForgotPassword2AR";


const MyProfilePageAR = () => {
    const { fetchWithAuth,logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const [ischangePass,setIsChangePass] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [profileData, setProfileData] = useState({
        firstName: '',
        lastName: '',
        mobile: '',
        email: '',
        country: '',
        street1: '',
        street2: '',
        buildingDetails: '',
        city: '',
        state: '',
        zipcode: ''
    });

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const response = await fetchWithAuth(PersonalProfileAPI);
                if (response.error) {
                    console.error("Error fetching profile:", response.error);
                } else {
                    setProfileData({
                        firstName: response.data.data.user.first_name || '',
                        lastName: response.data.data.user.last_name || '',
                        mobile: response.data.data.user.mobile || '',
                        email: response.data.data.user.email || '',
                        country: response.data.data.country || '',
                        street1: response.data.data.street1 || '',
                        street2: response.data.data.street2 || '',
                        buildingDetails: response.data.data.building || '',
                        city: response.data.data.city || '',
                        state: response.data.data.state || '',
                        zipcode: response.data.data.zip || ''
                    });
                }
            } catch (error) {
                console.error("Unexpected error:", error);
            }
        };

        fetchProfileData();
    }, []);

    //  all the toggles
    const handleEditToggle = () => {
        setIsEditable(!isEditable);
    };

    const handleChangePassToggle = () =>{
        setIsChangePass(!ischangePass);
    };


    const handleSave = async () => {
        const body ={
            "user": {
                "first_name":profileData.firstName ,
                "last_name":profileData.lastName ,
                "mobile": profileData.mobile,
                "email":profileData.email 
            },
            "country": profileData.country,
            "state": profileData.state,
            "city": profileData.city,
            "street1": profileData.street1,
            "street2": profileData.street2,
            "building": profileData.buildingDetails,
            "zip": profileData.zip
        }
        try {
            const response = await fetchWithAuth(UpdateProfileAPI, {body})

            if (response.error) {
                console.error("Error updating profile:", response.error);
            } else {
                console.log("Profile updated successfully!");
                setIsEditable(false); // Disable editing mode
            }
        } catch (error) {
            console.error("Unexpected error while saving profile:", error);
        }
    };

    // Reset password section
    const openResetPassForm  = () =>{
        handleChangePassToggle();
    }
    const handleChangePassFormClose = () =>{
        setIsChangePass(false);
    }
    const handleSuccesChangePass = () =>{
        navigate("/");
        logout();
    }

    return (
        <div className="body-container">
            <div>
                <NavBarMainAR />
                <div className="my-profile-main-container">
                    <div className="my-profile-sub-container">
                        <span className="my-profile-heading my-profile-heading-ar">ملفي الشخصي</span>
                        <div className="profile-cards-containers profile-cards-containers-ar">
                            <div className="user-name-section">
                                <UserIconAndName 
                                    UserName={`${profileData.firstName} ${profileData.lastName}`}
                                    direction="reverse"
                                />
                            </div>
                            <div className="input-fields-container input-fields-container-ar">
                                <div className="half-width-input">
                                    <InputTextField
                                        htmlFor={"firstname"}
                                        content={"الاسم الأول"}
                                        value={profileData.firstName}
                                        onChange={(e) => setProfileData({ ...profileData, firstName: e.target.value })}
                                        readOnly={!isEditable}
                                        dir="rtl"
                                        isArabic = {true}
                                    />
                                    <InputTextField
                                        htmlFor={"lastname"}
                                        content={"اسم العائلة"}
                                        value={profileData.lastName}
                                        onChange={(e) => setProfileData({ ...profileData, lastName: e.target.value })}
                                        readOnly={!isEditable}
                                        dir="rtl"
                                        isArabic = {true}
                                    />
                                </div>
                                <InputTextField
                                    htmlFor={"mobile"}
                                    content={"متحرك"}
                                    type={"number"}
                                    value={profileData.mobile}
                                    onChange={(e) => setProfileData({ ...profileData, mobile: e.target.value })}
                                    readOnly={!isEditable}
                                    dir="rtl"
                                    isArabic = {true}
                                />
                                <InputTextField
                                    htmlFor={"email"}
                                    content={"بريد إلكتروني"}
                                    type={"email"}
                                    value={profileData.email}
                                    onChange={(e) => setProfileData({ ...profileData, email: e.target.value })}
                                    readOnly={!isEditable}
                                    dir="rtl"
                                    isArabic = {true}
                                />
                            </div>
                        </div>
                        <div className="profile-cards-containers profile-cards-containers-ar">
                            <div className="input-fields-container">
                                <div className="profile-card-title profile-card-title-ar">موقع</div>
                                <InputTextField
                                    htmlFor={"country"}
                                    content={"دولة"}
                                    value={profileData.country}
                                    onChange={(e) => setProfileData({ ...profileData, country: e.target.value })}
                                    readOnly={!isEditable}
                                    dir="rtl"
                                    isArabic = {true}
                                />
                                <div className="half-width-input">
                                    <InputTextField
                                        htmlFor={"street1"}
                                        content={"شارع 1"}
                                        value={profileData.street1}
                                        onChange={(e) => setProfileData({ ...profileData, street1: e.target.value })}
                                        readOnly={!isEditable}
                                        dir="rtl"
                                        isArabic = {true}
                                    />
                                    <InputTextField
                                        htmlFor={"street2"}
                                        content={"شارع 2"}
                                        value={profileData.street2}
                                        onChange={(e) => setProfileData({ ...profileData, street2: e.target.value })}
                                        readOnly={!isEditable}
                                        dir="rtl"
                                        isArabic = {true}
                                    />
                                </div>
                                <InputTextField
                                    htmlFor={"builing-details"}
                                    content={"المنزل/رقم المبنى/الشقة"}
                                    value={profileData.buildingDetails}
                                    onChange={(e) => setProfileData({ ...profileData, buildingDetails: e.target.value })}
                                    readOnly={!isEditable}
                                    dir="rtl"
                                    isArabic = {true}
                                />
                                <div className="half-width-input">
                                    <InputTextField
                                        htmlFor={"city"}
                                        content={"مدينة"}
                                        value={profileData.city}
                                        onChange={(e) => setProfileData({ ...profileData, city: e.target.value })}
                                        readOnly={!isEditable}
                                        dir="rtl"
                                        isArabic = {true}
                                    />
                                    <InputTextField
                                        htmlFor={"state"}
                                        content={"الدولة / الإقليم / المنطقة"}
                                        value={profileData.state}
                                        onChange={(e) => setProfileData({ ...profileData, state: e.target.value })}
                                        readOnly={!isEditable}
                                        dir="rtl"
                                        isArabic = {true}
                                    />
                                </div>
                                <InputTextField
                                    htmlFor={"zipcode"}
                                    content={"الرمز البريدي"}
                                    value={profileData.zipcode}
                                    onChange={(e) => setProfileData({ ...profileData, zipcode: e.target.value })}
                                    readOnly={!isEditable}
                                    dir="rtl"
                                    isArabic = {true}
                                />
                            </div>
                        </div>
                        <div className="save-profile-button-containers save-profile-button-containers-ar">
                            <PrimaryMutedButton
                                label={"تغيير كلمة المرور"}
                                onClick={openResetPassForm}
                            />
                            <PrimarySolidButton
                                label={isEditable ? "يحفظ" : "تحرير الملف الشخصي"}
                                onClick={isEditable ? handleSave : handleEditToggle}
                            />
                        </div>
                    </div>
                    { ischangePass && 
                        <ForgotPassword2AR 
                            onClose={handleChangePassFormClose}
                            onComplete={handleSuccesChangePass}
                        />
                    }
                </div>
            </div>
            <FooterAR />
        </div>
    );
};

export default MyProfilePageAR;

