import React from "react";
import '../../Common.css';
import './CategoryGridCard.css';
import { baseUrl } from "../../services/BaseUrl";


const CategoryGridCard = ({id,name,count,image,onClick})=>{
    return (
        <div className="category-grid-card" id={id} onClick={onClick}>
            <img src={`${baseUrl}${image}`} alt="" />
            <div className="category-name">{name}</div>
            <span>{count} Service</span>  {/*  {count > 1 ? "s" : ""}  */}
        </div>
    )
}

export default CategoryGridCard;