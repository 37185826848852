import React from "react";
import './TabSwitch.css';

const TabSwitch = ({ id, name, htmlFor, content, selectedValue, onSelection }) =>{
    const isSelected = selectedValue === id;

    return(
        <div className="tab-switch-input-container">
            <label
                className={`tab-name-box ${isSelected ? "selected" : ""}`}
                htmlFor={htmlFor}
            >
                {content}
            </label>
            <input
                type="radio"
                id={id}
                name={name}
                value={id}
                onChange={() => onSelection(id)}
                hidden
            />
        </div>
    )
}

export default TabSwitch;