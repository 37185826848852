import React from "react";
import '../../../Common.css';
import '../Buttons.css';
import './PrimaryStrokeButton.css';

const PrimaryStrokeButton = ({label,onClick})=>{
    return (
        <div className="btn primary-stroke-btn" onClick={onClick}>{label}</div>
    )
}

export default PrimaryStrokeButton;