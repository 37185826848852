import React,{useEffect, useState,useContext} from "react";
import './NavBarMain.css';
import './NavBarMainAR.css';
import { useNavigate,useLocation } from "react-router-dom";
import { AuthContext } from "../../services/AuthContext";
import { NotificaitonCount } from "../../services/Api";
import { PersonalProfileAPI } from "../../services/Api";
// images
import navlogo from '../../assets/navbar_logo.png';

import NotificationIcon from "../NotificationIcon/NotificationIcon";
import TabSwitch from "../InputFields/TabSwitch/TabSwitch";
import UserIconAndName from "../UserIconAndName/UserIconAndName";
import LanguageSwitch from "../ChangeLanguage/LanguageSwitch";
import ScuccessPopUp from "../PopUps/AccountCreationSuccess/ScuccessPopUp";


const NavBarMainAR = ()=>{
    const { fetchWithAuth,logout,isAuthenticated, isAuthLoading,showLoginSuccessPopup } = useContext(AuthContext);
    const [selectedValue, setSelectedValue] = useState("home");
    const [notificationCount,setNotificationCount] =  useState();
    const [isOptions,setIsOptions] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const [profileData, setProfileData] = useState({
            firstName: '',
            lastName: '',
        });
    

    const [harmburgerState,setHarmburgerState] = useState(false);
    const [screenSize, setScreenSize] = useState(window.innerWidth);
    
    const handleSelection = (value,route) => {
        setSelectedValue(value);
        setTimeout(() => navigate(route), 0);
        
    };
    // Map route paths to tab values
    const routeToTabMapping = {
        "/ar/": "home",
        "/ar/services": "services",
        "/ar/service-bookings": "service-bookings",
        "/ar/help-support": "help-support",    
    };

    const goToNotificationPage = () =>{
        navigate('/ar/notifications')
    }
    const goToMyProfile = ()=>{
        navigate('/ar/my-profile')
    }
    const showOptions = () =>{
        setIsOptions(!isOptions)
    }
    const fetchProfileData = async () => {
        try {
            const response = await fetchWithAuth(PersonalProfileAPI);
            if (!response.error) {
                setProfileData({
                    firstName: response.data.data.user.first_name || '',
                    lastName: response.data.data.user.last_name || '',
                });
            } else {
                console.error("Error fetching profile:", response.error);
            }
        } catch (error) {
            console.error("Unexpected error:", error);
        }
    };

    const fetchNotificationCount = async () => {
        try {
            const response = await fetchWithAuth(NotificaitonCount);
            if (!response.error) {
                setNotificationCount(parseInt(response.data.data.count, 10));
            } else {
                console.error("Error fetching notifications:", response.error);
            }
        } catch (error) {
            console.error("Error fetching notifications:", error);
        }
    };

    useEffect(() => {
        
            fetchProfileData();
            fetchNotificationCount();
        
    }, []);

    useEffect(() => {
        const currentTab = routeToTabMapping[location.pathname] || "";
        setSelectedValue(currentTab);
    }, [location.pathname]);


    // To toggle the navbar items in small screens
    const handleHarburgerClick = ()=>{
        setHarmburgerState(!harmburgerState)
    }

    useEffect(() => {
        const handleResize = () => {
            setScreenSize(window.innerWidth);
        }
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    });
    


    if (isAuthLoading) {
        return <div>Loading...</div>; // Show a loading indicator until auth state resolves.
    }
    return (
        <div className="main-nav-container">
            <div className="main-nav-inner-container">
                <div className="image-tab-section image-tab-section-ar">
                    <div className="nav-logo-img nav-logo-img-ar">
                        <img src={navlogo} alt="Navbar logo"/>
                    </div>
                    {(harmburgerState || screenSize > 992) && (
                    <div className="tab-section tab-section-ar">

                        <TabSwitch
                        key={"home"}
                        content={"بيت"}
                        name={"nav-bar-tabs"}
                        id={"home"}
                        htmlFor={"home"}
                        selectedValue={selectedValue}
                        onSelection={() => handleSelection("home","/ar/")} 
                        />

                        <TabSwitch
                        key={"services"}
                        content={"خدمات"}
                        name={"nav-bar-tabs"}
                        id={"services"}
                        htmlFor={"services"}
                        selectedValue={selectedValue}
                        onSelection={() => handleSelection("services","/ar/services")} 
                        />

                        <TabSwitch
                        key={"service-bookings"}
                        content={"تاريخ حجز الخدمة"}
                        name={"nav-bar-tabs"}
                        id={"service-bookings"}
                        htmlFor={"service-bookings"}
                        selectedValue={selectedValue}
                        onSelection={() => handleSelection("service-bookings","/ar/service-bookings")} 
                        />

                        <TabSwitch
                        key={"help-support"}
                        content={"هنا للمساعدة، في كل خطوة!"}
                        name={"nav-bar-tabs"}
                        id={"help-support"}
                        htmlFor={"help-support"}
                        selectedValue={selectedValue}
                        onSelection={() => handleSelection("help-support","#")} 
                        />

                        <LanguageSwitch/>
                        
                    </div>
                    )}
                </div>

                <div className="notification-name-section notification-name-section-ar">
                    <div className="notification-box">
                        <NotificationIcon count={notificationCount} onClick={goToNotificationPage}/>
                    </div>
                    <i class="harmburger-icon fa-solid fa-bars" onClick={handleHarburgerClick}></i>
                    <div className="name-icon-box" onClick={showOptions}>
                        <UserIconAndName 
                            UserName={`${profileData.firstName} ${profileData.lastName}` } 
                            direction="normal" 
                            place="nav"
                        />
                        { isOptions &&
                            <div className="other-profile-options">
                                <div className="my-profile-options-box my-profile-options-box-ar" onClick={goToMyProfile}>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M8.00002 8.66667C9.84097 8.66667 11.3334 7.17428 11.3334 5.33333C11.3334 3.49238 9.84097 2 8.00002 2C6.15907 2 4.66669 3.49238 4.66669 5.33333C4.66669 7.17428 6.15907 8.66667 8.00002 8.66667Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M13.3334 14.0003C13.3334 12.5858 12.7715 11.2293 11.7713 10.2291C10.7711 9.2289 9.41451 8.66699 8.00002 8.66699C6.58553 8.66699 5.22898 9.2289 4.22878 10.2291C3.22859 11.2293 2.66669 12.5858 2.66669 14.0003" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                    <div className="text-options">
                                        ملفي الشخصي     
                                    </div>
                                </div>
                                <div className="divider"></div>
                                <div className="my-profile-options-box my-profile-options-box-ar" onClick={logout}>
                                    <div>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
                                            <path d="M6 14H3.33333C2.97971 14 2.64057 13.8595 2.39052 13.6095C2.14048 13.3594 2 13.0203 2 12.6667V3.33333C2 2.97971 2.14048 2.64057 2.39052 2.39052C2.64057 2.14048 2.97971 2 3.33333 2H6" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M10.6667 11.3337L14 8.00033L10.6667 4.66699" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                                            <path d="M14 8H6" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                    <div className="text-options">
                                        تسجيل الخروج
                                    </div>
                                </div>
                            </div>
                        }
                    </div>
                </div>
                
            </div>
            {showLoginSuccessPopup && <ScuccessPopUp message={"مرحبًا بعودتك"}/>}
        </div>
    )
}


export default NavBarMainAR;