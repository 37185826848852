import React from "react";
import '../../Common.css';
import './Footer.css';
import logo from '../../assets/logo.png';


const FooterAR = ()=>{
    return (
        <div className="footer-container">
            <div className="footer-inner-container footer-inner-container-ar">
                <div className="contact-container contact-container-ar">
                    <div className="footer-logo-box">
                        <img src={logo} alt="Fraternity Logo" />
                    </div>
                    <div className="contact contact-ar">
                        <div>
                            <div className="heading">اتصل بنا</div>
                            <div className="address">1234 شارع إلم، جناح 567 سبرينغفيلد، نيويورك 12345 الولايات المتحدة</div>
                        </div>
                        <div>
                            <div>+1 (555) 123-4567 : هاتف </div>
                            <div>support@example.com : بريد إلكتروني </div>
                        </div>
                    </div>
                </div>
                <div className="terms-privacy-box">
                    <span>الشروط والأحكام</span>
                    <span>سياسة الخصوصية</span>
                </div>
            </div>
        </div>
    )
}

export default FooterAR;