import React from "react";
import { useContext, useEffect,useState } from "react";
import './LocationForm.css';
import moment from 'moment';

// components
import PrimarySolidButton from "../../Buttons/PrimarySolidButton/PrimarySolidButton";
import PopUpMainHeading from "../../Heading/PopUpMainHeading/PopUpMainHeading";
import AccountWarning from "../TextItems/AccountWarning/AccountWarning";
import PopUpSubHeading from "../../Heading/PopUpSubHeading/PopUpSubHeading";
import InputTextField from "../../InputFields/InputTextField/InputTextField";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";

const LocationForm2 = ({onClose,onInputChange,onSubmit,onLoginNow,errorMessage}) =>{

    const handleOverlayClick = (e) =>{
        if(e.target.className === 'login-popup-overlay'){
            onClose();
        };
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        onInputChange(name, value);
    };

    return (
        <div className="login-popup-overlay" onClick={handleOverlayClick}>
            <div className="register-pop-up">
                <div className="register-head-section">
                    <PopUpMainHeading content={"Enter Location"}/>
                    <PopUpSubHeading content={"Enter your location details so we can reach you easily!"}/>
                </div>
                <form className="login-input-button-section">
                    {errorMessage && <ErrorMessage message={errorMessage}/>}
                    <div className="input-field-section">
                        <InputTextField htmlFor={"country"} onChange={handleInputChange} name={"country"}  content={'Country'} />
                        <InputTextField htmlFor={"state"} onChange={handleInputChange} name={"state"} content={'State/Province/Region'} />
                        <div className="half-width-input gap-62">
                            <InputTextField htmlFor={"street1"} onChange={handleInputChange} name={"street1"} content={'Street 1'} />
                            <InputTextField htmlFor={"street2"} onChange={handleInputChange} name={"street2"} content={'Street 2'} />
                        </div>
                        <InputTextField htmlFor={"housenumber"} onChange={handleInputChange} name={"building"} content={'House/Building Number/Apartment'}/>
                        <div className="half-width-input gap-62">
                            <InputTextField htmlFor={"city"} onChange={handleInputChange} name={"city"} content={'City'} />
                            <InputTextField htmlFor={"zipcode"} onChange={handleInputChange} name={"zip"} content={'Zipcode'} />
                        </div>
                    </div>
                    
                    <div className={`forgot-already-account-box margin-new`}>
                        <AccountWarning content={"Already have an account?"}
                        link={"Log in now"} onClick={onLoginNow}/>
                        
                        <PrimarySolidButton label={"Continue"} onClick={onSubmit} />  
                    </div>
                </form>
            </div>
        </div>
    )
}
export default LocationForm2;