import React,{useState} from "react";
import '../../../Common.css'
import '../CommonSearch.css'
import './SecondarySearch.css'

const SecondarySearchBox = ({ placeholder, onSubmit,type="submit" })=>{
    const [inputValue, setInputValue] = useState("");

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    };

    const handleFormSubmit = (e) => {
        e.preventDefault();
        onSubmit(inputValue); // Pass the input value to the parent
    };
    return(
            <form className="search-container search-container-secondary" onSubmit={handleFormSubmit}>
                <div className="icon-search-container">
                    <div className="search-svg-icon">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path d="M11 19C15.4183 19 19 15.4183 19 11C19 6.58172 15.4183 3 11 3C6.58172 3 3 6.58172 3 11C3 15.4183 6.58172 19 11 19Z" stroke="#666666" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M21.0002 21L16.7002 16.7" stroke="#666666" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                    </div>
                    <input type="text" value={inputValue} 
                        placeholder={placeholder} 
                        className="search-input search-input-secondary"
                        onChange={handleInputChange}
                    />
                </div>
                <div className="search-button-container">
                    <button type={type} className="search-button search-button-secondary">
                        Search
                    </button>
                </div>
            </form>
    )
}

export default SecondarySearchBox;