import React from "react";
import { useContext, useEffect,useState } from "react";
import './LocationForm.css';
import moment from 'moment';

// components
import PrimarySolidButton from "../../Buttons/PrimarySolidButton/PrimarySolidButton";
import PopUpMainHeading from "../../Heading/PopUpMainHeading/PopUpMainHeading";
import AccountWarningAR from "../TextItems/AccountWarning/AccountWarningAR";
import PopUpSubHeading from "../../Heading/PopUpSubHeading/PopUpSubHeading";
import InputTextField from "../../InputFields/InputTextField/InputTextField";
import ErrorMessageAR from "../../ErrorMessage/ErrorMessageAR";

const LocationForm2AR = ({onClose,onInputChange,onSubmit,onLoginNow,errorMessage}) =>{

    const handleOverlayClick = (e) =>{
        if(e.target.className === 'login-popup-overlay'){
            onClose();
        };
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        onInputChange(name, value);
    };

    return (
        <div className="login-popup-overlay" onClick={handleOverlayClick}>
            <div className="register-pop-up">
                <div className="register-head-section">
                    <PopUpMainHeading content={"أدخل الموقع"}/>
                    <PopUpSubHeading content={"أدخل تفاصيل موقعك حتى نتمكن من الوصول إليك بسهولة!"}/>
                </div>
                <form className="login-input-button-section">
                    {errorMessage && <ErrorMessageAR message={errorMessage}/>}
                    <div className="input-field-section">
                        <InputTextField htmlFor={"country"} onChange={handleInputChange} name={"country"}  content={'دولة'} isArabic={true} dir="rtl" />
                        <InputTextField htmlFor={"state"} onChange={handleInputChange} name={"state"} content={'الدولة / الإقليم / المنطقة'} isArabic={true} dir="rtl" />
                        <div className="half-width-input gap-62 arabic-input">
                            <InputTextField htmlFor={"street1"} onChange={handleInputChange} name={"street1"} content={'شارع 1'} isArabic={true} dir="rtl" />
                            <InputTextField htmlFor={"street2"} onChange={handleInputChange} name={"street2"} content={'شارع 2'} isArabic={true} dir="rtl" />
                        </div>
                        <InputTextField htmlFor={"housenumber"} onChange={handleInputChange} name={"building"} content={'المنزل/رقم المبنى/الشقة'} isArabic={true} dir="rtl"/>
                        <div className="half-width-input gap-62 arabic-input">
                            <InputTextField htmlFor={"city"} onChange={handleInputChange} name={"city"} content={'مدينة'} isArabic={true} dir="rtl" />
                            <InputTextField htmlFor={"zipcode"} onChange={handleInputChange} name={"zip"} content={'الرمز البريدي'} isArabic={true} dir="rtl" />
                        </div>
                    </div>
                    
                    <div className={`forgot-already-account-box margin-new arabic-flex`}>
                        <AccountWarningAR content={"هل لديك حساب بالفعل؟"}
                        link={"قم بتسجيل الدخول الآن"} onClick={onLoginNow}/>
                        
                        <PrimarySolidButton label={"يكمل"} onClick={onSubmit} />  
                    </div>
                </form>
            </div>
        </div>
    )
}
export default LocationForm2AR;