import React from "react";
import './InputMobileField.css';

const MobileInput = ({htmlFor,content,id,value,onChange,dir="ltr",isArabic=false})=>{
    const handleInputChange = (event) => {
        const value = event.target.value.replace(/[^0-9]/g, "");
        event.target.value = value;
    };
    
    return (
        <div className={`phone-input-wrapper  ${isArabic?"yes-arabic":""}`}>
            <label htmlFor={htmlFor}>{content}</label>
            <div className={`phone-input-container ${isArabic?"phone-input-container-ar":""} `}>
                <div className={`mobile-icon-container ${isArabic?"mobile-icon-container-ar":""}`}>
                    <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 22 22" fill="none">
                    <path d="M15.5833 1.83301H6.41659C5.40406 1.83301 4.58325 2.65382 4.58325 3.66634V18.333C4.58325 19.3455 5.40406 20.1663 6.41659 20.1663H15.5833C16.5958 20.1663 17.4166 19.3455 17.4166 18.333V3.66634C17.4166 2.65382 16.5958 1.83301 15.5833 1.83301Z" stroke="#666666" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M11 16.5H11.0092" stroke="#666666" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <input
                    type="text"
                    onInput={handleInputChange}
                    id={id}
                    value={value}
                    onChange={onChange}
                    dir={dir}
                />
            </div>
        </div>
        );
}

export default MobileInput;