import React, { use, useContext } from "react";
import './ServiceDetail.css';
import { useEffect,useState } from "react";
import { useNavigate,useParams } from "react-router-dom";
import { AuthContext } from "../../services/AuthContext";

// components
import NavBarMainAR from "../../components/NavBarMain/NavBarMainAR";
import NavBarAR from "../../components/NavBar/NavBarAR";
import FooterAR from "../../components/Footer/FooterAR";
import ServiceListCardAR from "../../components/ServiceListCard/ServiceListCardAR";
import { ServicesListView } from "../../services/Api";
import { SingleServiceCategoryAPI } from "../../services/Api";

// images
import plumbing from '../../assets/plumbing.png';

const ServiceDetailPageAR = () =>{
    const {id} = useParams();
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [category,setCategory] = useState([]);
    const navigate = useNavigate();
    const {isAuthenticated} = useContext(AuthContext)

    useEffect(() => {
        const fetchServices = async () => {
        const params = `category=${id}`
        try {
            const response = await ServicesListView({params});
            setServices(response.data.data);
        } 
        catch (error) {
            console.error("Error fetching services:", error);
        } 
        finally {
            setLoading(false);
        }
        };
    fetchServices();
    },[]);


    useEffect(() => {
        const fetchCategory = async () => {
        const params = `${id}`
        try {
            const response = await SingleServiceCategoryAPI({params});
            setCategory(response.data.data);
        } 
        catch (error) {
            console.error("Error fetching Category:", error);
        } 
        };
        fetchCategory();
    },[]);

    const handleServiceClick = (id)=>{
        navigate(`/ar/book-service/${id}/`)
    }

    return(
        <div className="body-container">
            <div>
                {isAuthenticated?<NavBarMainAR/>:<NavBarAR/> }
                <div className="page-title-box">
                    <div className="page-title">
                    {category?.name ? `${category.name} Services` : "Services"}
                    </div>
                    <div className="page-sub-title">
                        اختر الخدمة
                    </div>
                </div>
                <div className="service-card-container">
                    <div className="service-card-box">
                        {services.map((service)=>(
                            <ServiceListCardAR 
                            id={service.id}
                            image={service.image}
                            heading ={service.name}
                            subHeading={service.description}
                            hasButton={true}
                            onClick={()=>handleServiceClick(service.id)}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <FooterAR/>
        </div>
    )
}

export default ServiceDetailPageAR;