import React from "react";
import '../Buttons.css';
import './PrimaryMutedButton.css'

const PrimaryMutedButton = ({label,onClick,disabled=false}) =>{
    return(
        <div className={`btn primary-muted-btn ${disabled ? "disabled" : ""}`}
            onClick={!disabled ? onClick:null}>{label}
        </div>
    )
}

export default PrimaryMutedButton;