import React from 'react';
import './ShowLogPopUp.css';
// componenets
import PrimarySolidButton from '../../Buttons/PrimarySolidButton/PrimarySolidButton';
import PrimaryMutedButton from '../../Buttons/PrimaryMutedButton/PrimaryMutedButton';

import frame from '../../../assets/frame.png';
import { baseUrl } from '../../../services/BaseUrl';


const ShowLogPopUp =({onClose,onRegisterClick,onLoginClick})=>{

    return(
        <div className="login-popup-overlay">
        <div className="register-pop-up pop-up-gap">

            <button className="close-button-u" onClick={onClose}>
                <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <path d="M21 7L7 21" stroke="#666666" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M7 7L21 21" stroke="#666666" strokeLinecap="round" strokeLinejoin="round"/>
                </svg>
            </button>

            <div className="popup-image">
                <img src={frame} alt="Login/Register" />
                <p className="popup-text">
                    Please Log in or create an account to book your service.
                </p>
            </div>

            <div className="popup-buttons">
                <PrimaryMutedButton label={"Create Account"} onClick={onRegisterClick}/>
                <PrimarySolidButton label={"Log In"} onClick={onLoginClick}/>
            </div>
        </div>
        </div>
    )
}
export default ShowLogPopUp;