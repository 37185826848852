import React,{useState,useEffect,useContext} from "react";
import { useLocation } from "react-router-dom";
import { AuthContext } from "../../services/AuthContext";
// components
import NavBarAR from "../../components/NavBar/NavBarAR";
import NavBarMainAR from "../../components/NavBarMain/NavBarMainAR";
import FooterAR from "../../components/Footer/FooterAR";
import CategoryAndSrviceTabAR from "../../components/CategoryAndServiceTab/CategoryAndServiceTabAR";
import SecondarySearchBoxAR from "../../components/Search/SecondarySearch/SecondarySearchAR";
// css
import '../../Common.css';
// import './services.css'

const ServicesAR = ()=>{
    const [activeTab, setActiveTab] = useState("categories"); 
    const [searchValue, setSearchValue] = useState(""); 
    const {isAuthenticated} = useContext(AuthContext)
    const location = useLocation();

    useEffect(() => {
        const tab = location.state?.tab;
        if (tab) {
            setActiveTab(tab); 
        }
    }, [location.state]);

    const getPlaceholder = () => {
        return activeTab === "categories"
            ? "فئة البحث"
            : "خدمة البحث";
    };

    const handleSearchSubmit = (value) => {
        setSearchValue(value); // Update search value
    };
    return(
        <div className="body-container">
            <div>
            {isAuthenticated?<NavBarMainAR/>:
                <NavBarAR/> 
            }
                <div className="page-title-box">
                    <div className="page-title">
                        Our Services
                    </div>
                    <div className="page-text-content">
                        Discover a range of convenient services designed to meet your needs and
                        make everyday life simpler
                    </div>
                </div>
                <div className="service-search-container">
                    <div className="service-seach-box">
                        <SecondarySearchBoxAR
                            placeholder={getPlaceholder()}
                            onSubmit={handleSearchSubmit}
                        />
                    </div>
                </div>
                <div className="category-service-container">
                    <CategoryAndSrviceTabAR
                        activeClassName = 'selected'
                        tab={activeTab}
                        search={searchValue}
                        onTabChange={setActiveTab}
                    />
                </div>
                
            </div>
            <FooterAR/>
        </div>
    )
};

export default ServicesAR;