import React from "react";
import './ServiceListCard2.css'
import { baseUrl } from "../../services/BaseUrl";

// components

const ServiceListCard2 = ({key,image,heading,subHeading,createdDate,scheduledDate,scheduledTime,status='Pending',hasButton=true,onButtonClick}) =>{
    const state = status.trim().toLowerCase().replace(/\s+/g, "_");
    return(
        <div className="service-list-card-main-container dis-relative cus-padding" id={key}>
            <div className="image-title-container">
                <div className="service-list-image-box">
                    <img src={`${baseUrl}${image}`} alt="Service Detail" />
                </div>
                <div className="service-list-detail-text">
                    <div className="service-detail-heading">
                        {heading}
                    </div>
                    <div className="service-detail-subheading">
                        {subHeading}
                    </div>
                    <div className="service-detail-subheading dis-flex dis-gap">
                        <div className="date-box">
                            <span>Created date:</span>
                            <span>{createdDate}</span>
                        </div>
                        <div className="date-box">
                            <span>Scheduled date and time:</span>
                            <span>{scheduledDate}</span>
                            <span>,</span>
                            <span>{scheduledTime}</span>
                        </div>
                    </div>
                </div>
            </div>
            {hasButton && (<div className="eye-detail-icon" onClick={onButtonClick}>
                <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 24 24" fill="none">
                <path d="M2.06202 12.3481C1.97868 12.1236 1.97868 11.8766 2.06202 11.6521C2.87372 9.68397 4.25153 8.00116 6.02079 6.81701C7.79004 5.63287 9.87106 5.00073 12 5.00073C14.129 5.00073 16.21 5.63287 17.9792 6.81701C19.7485 8.00116 21.1263 9.68397 21.938 11.6521C22.0214 11.8766 22.0214 12.1236 21.938 12.3481C21.1263 14.3163 19.7485 15.9991 17.9792 17.1832C16.21 18.3674 14.129 18.9995 12 18.9995C9.87106 18.9995 7.79004 18.3674 6.02079 17.1832C4.25153 15.9991 2.87372 14.3163 2.06202 12.3481Z" stroke="#108AC6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                <path d="M12 15C13.6569 15 15 13.6569 15 12C15 10.3431 13.6569 9 12 9C10.3431 9 9 10.3431 9 12C9 13.6569 10.3431 15 12 15Z" stroke="#108AC6" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </div>
            )}

            <div className={`state-card-container ${state}`}>
                {status}
            </div>
        </div>
    )
}

export default ServiceListCard2;