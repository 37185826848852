import React from "react";
import { useContext, useEffect,useState } from "react";
import './LocationForm.css';
import moment from 'moment';

// components
import PrimarySolidButton from "../../Buttons/PrimarySolidButton/PrimarySolidButton";
import PopUpMainHeading from "../../Heading/PopUpMainHeading/PopUpMainHeading";
import AccountWarning from "../TextItems/AccountWarning/AccountWarning";
import PopUpSubHeading from "../../Heading/PopUpSubHeading/PopUpSubHeading";
import InputTextField from "../../InputFields/InputTextField/InputTextField";

const LocationFormAR = ({data,isRegForm=false,onSave}) =>{
    const [formData, setFormData] = useState(data);
    const formattedDate = moment(data.visit_date).format("DD-MMM-YYYY");
    const formattedTime = moment(data.visit_time, "HH:mm:ss").format("hh A");
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevData) => ({ ...prevData, [name]: value }));
    };
    const handleSave = () => {
        onSave(formData); // Pass updated data to the parent
    };

    return (
        <div className="login-popup-overlay">
            <div className="register-pop-up">
                <div className="register-head-section">
                    <PopUpMainHeading content={"أدخل الموقع"}/>
                    <PopUpSubHeading content={"أدخل تفاصيل موقعك حتى نتمكن من الوصول إليك بسهولة!"}/>
                </div>
                <form className="login-input-button-section">
                    <div className="input-field-section">
                        <InputTextField htmlFor={"country"} onChange={handleInputChange} name={"country"}  content={'دولة'} value={formData.country} isArabic={true} dir="rtl" />
                        <InputTextField htmlFor={"state"} onChange={handleInputChange} name={"state"} content={'الدولة / الإقليم / المنطقة'} value={formData.state} isArabic={true} dir="rtl"/>
                        <div className="half-width-input gap-62 arabic-input">
                            <InputTextField htmlFor={"street1"} onChange={handleInputChange} name={"street1"} content={'شارع 1'} value={formData.street1} isArabic={true} dir="rtl"/>
                            <InputTextField htmlFor={"street2"} onChange={handleInputChange} name={"street2"} content={'شارع 2'} value={formData.street2} isArabic={true} dir="rtl"/>
                        </div>
                        <InputTextField htmlFor={"housenumber"} onChange={handleInputChange} name={"building"} content={'المنزل/رقم المبنى/الشقة'} value={formData.building} isArabic={true} dir="rtl"/>
                        <div className="half-width-input gap-62 arabic-input">
                            <InputTextField htmlFor={"city"} onChange={handleInputChange} name={"city"} content={'مدينة'} value={formData.city} isArabic={true} dir="rtl"/>
                            <InputTextField htmlFor={"zipcode"} onChange={handleInputChange} name={"zip"} content={'الرمز البريدي'} value={formData.zip} isArabic={true} dir="rtl"/>
                        </div>
                    </div>
                    
                    <div className={`forgot-already-account-box margin-new ${isRegForm ? "" : "not-reg-form"}`}>
                        {isRegForm && <AccountWarning content={"Already have an account?"}
                        link={"Log in now"}/>}
                        
                        <PrimarySolidButton label={"الرمز البريدي"} onClick={handleSave} />  
                    </div>
                </form>
            </div>
        </div>
    )
}
export default LocationFormAR;