import React from "react";
import './PrimaryTextAreaField.css';

const PrimaryTextAreaField = ({htmlFor,content,value,dir="ltr",isArabic=false}) =>{
    return(
        <div className={`text-area-field-container ${isArabic?"yes-arabic":""}`}>
            <label htmlFor={htmlFor}>{content}</label>
            <div className={`text-area-box ${isArabic?"text-area-box-ar":""} `}>
                <textarea dir={dir} value={value}/>
            </div>
        </div>
    )
}

export default PrimaryTextAreaField;