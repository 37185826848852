import React from "react";
import './RadioTileInput.css'

const RadioTileInput = ({ id, name, htmlFor, content, selectedValue, onSelection,muted=false }) =>{
    const isSelected = selectedValue === id;

    return(
        <div className={`radio-tile-input-container ${muted ? "muted" : ""}`}>
            <label
                className={`radio-tile ${isSelected ? "selected" : ""}`}
                htmlFor={htmlFor}
            >
                {content}
            </label>
            <input
                type="radio"
                id={id}
                name={name}
                value={id}
                onChange={() => onSelection(id)}
                disabled={muted}
                hidden
            />
        </div>
    )
}

export default RadioTileInput;