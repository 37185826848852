import React from "react";
import './NotificationBox.css'
import { baseUrl } from "../../services/BaseUrl";

const NotificationBox = ({image,message,date,key}) =>{
    return(
        <div key={key} className="notification-box-container">
            <div className="notification-image-message-container">
                <div className="notification-status-image">
                    <img src={image} alt="" />
                </div>
                <div className="notification-message">{message}</div>
            </div>
            <div className="notification-date">{date}</div>
        </div>
    )
}

export default NotificationBox;