import logo from './logo.svg';
import React from 'react';
import {BrowserRouter as Router,Routes,Route,Link,Navigate} from 'react-router-dom';
import { AuthProvider } from './services/AuthContext';
import ProtectedRoute from './services/PrivateRoute';
import './App.css';
// pages
import NavBar from './components/NavBar/NavBar';
import Home from './pages/home';
import Services from './pages/ServicePage/services';
import Notifications from './pages/NoificationPage/Notifications';
import ServiceDetailPage from './pages/ServiceDetailPage/ServiceDetail';
import BookService from './pages/BookServicePage/BookService';
import EditServiceBooking from './pages/BookServicePage/EditServiceBooking';
import ServiceBookings from './pages/ServiceBookings/ServiceBookings';
import MyProfilePage from './pages/MyProfile/MyProfilePage';

import NavBarAR from './components/NavBar/NavBarAR';
import HomeAR from './pages/homeAR';
import ServicesAR from './pages/ServicePage/servicesAR';
import ServiceBookingsAR from './pages/ServiceBookings/ServiceBookingsAR';
import NotificationsAR from './pages/NoificationPage/NotificationsAR';
import MyProfilePageAR from './pages/MyProfile/MyProfilePageAR';
import ServiceDetailPageAR from './pages/ServiceDetailPage/ServiceDetailAR';
import BookServiceAR from './pages/BookServicePage/BookServiceAR';
import EditServiceBookingAR from './pages/BookServicePage/EditServiceBookingAR';


// PrivateRoute to secure authenticated pages
// const PrivateRoute = ({ children }) => {
//   const authToken = localStorage.getItem('authToken'); // Replace this with context if needed
//   return authToken ? children : <Navigate to="/" />;
// };

function App() {
  return (
        <div className="App">
          <Router>
            <AuthProvider>
              <Routes>

                {/* English Routes */}
                <Route path='/' element={<Home/>}/>
                <Route path='/services' element={<Services/>}/>
                <Route path='/select-service/:id' element={<ServiceDetailPage/>}/>
                <Route path='/book-service/:id' element={<BookService/>}/>

                {/* Arabic Routes */}
                <Route path='/ar' element={<HomeAR/>}/>
                <Route path='/ar/services' element={<ServicesAR/>}/>
                <Route path='/ar/select-service/:id' element={<ServiceDetailPageAR/>}/>
                <Route path='/ar/book-service/:id' element={<BookServiceAR/>}/>

                {/* ******************** PRIVATE ROUTES  BELOW *************** */}

                {/* English Routes */}
                <Route path='/notifications' element={<ProtectedRoute><Notifications /></ProtectedRoute>} />
                <Route path='/edit-service-booking/:id' element={<ProtectedRoute><EditServiceBooking /></ProtectedRoute>} />
                <Route path='/service-bookings' element={<ProtectedRoute><ServiceBookings /></ProtectedRoute>} />
                <Route path='/my-profile' element={<ProtectedRoute><MyProfilePage /></ProtectedRoute>} />

                {/* Arabic Routes */}
                <Route path='/ar/service-bookings' element={<ProtectedRoute><ServiceBookingsAR /></ProtectedRoute>} />
                <Route path='/ar/notifications' element={<ProtectedRoute><NotificationsAR /></ProtectedRoute>} />
                <Route path='/ar/my-profile' element={<ProtectedRoute><MyProfilePageAR /></ProtectedRoute>} />
                <Route path='/ar/edit-service-booking/:id' element={<ProtectedRoute><EditServiceBookingAR /></ProtectedRoute>} />

              </Routes>
            </AuthProvider>
          </Router>
      </div>
  );
}

export default App;
