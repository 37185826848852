import React,{ useContext,useEffect,useState } from 'react';
import './Notifications.css';
import { AuthContext } from "../../services/AuthContext";
import { AllNotifications } from '../../services/Api';
import moment from 'moment';
// components
import NavBar from "../../components/NavBar/NavBar";
import NavBarMain from '../../components/NavBarMain/NavBarMain';
import Footer from "../../components/Footer/Footer";
import NotificationBox from '../../components/NotificationBox/NotificationBox';
import Pagination from '../../components/Pagination/Pagination';
// images
import Created from '../../assets/Created.png';
import Accepted from '../../assets/Accepted.png';
import Rejected from '../../assets/Rejected.png';
import Completed from '../../assets/Completed.png';
import Cancelled from '../../assets/Cancelled.png';
import Registered from '../../assets/Registered.png';


const Notifications = () => {
    const { fetchWithAuth } = useContext(AuthContext);
    const [notifications, setNotifications] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);

    useEffect(() => {
        const getNotifications = async () => {
            try {
            const params = `?page=${currentPage}`
            const response = await fetchWithAuth(AllNotifications,{params});
            if (response.error) {
                console.error("Error fetching notifications:", response.error);
            } else {
                setNotifications(response.data.data);
                setTotalPages(10)
            }
            } catch (error) {
            console.error("Unexpected error:", error);
            }
        };
        getNotifications();
    }, [currentPage]);

    // Helper function to group notifications by date
    const groupNotificationsByDate = (notifications) => {
        return notifications.reduce((acc, item) => {
        const dateKey = moment(item.timestamp).startOf("day").format("YYYY-MM-DD");
        if (!acc[dateKey]) {
            acc[dateKey] = [];
        }
        acc[dateKey].push(item);
        return acc;
        }, {});
    };

    // Group notifications by date
    const groupedNotifications = groupNotificationsByDate(notifications);

    // Get the date labels
    const today = moment().startOf("day").format("YYYY-MM-DD");
    const yesterday = moment().subtract(1, "day").startOf("day").format("YYYY-MM-DD");

    // mapping all the images for each state
    const categoryImages = {
        Created: Created,
        Accepted: Accepted,
        Rejected: Rejected,
        Completed: Completed,
        Cancelled: Cancelled,
        Registered: Registered,
    };

    return (
        <div className="body-container">
            <div>
                <NavBarMain />
                <div className="page-title-box">
                    <div className="page-title">Notifications</div>
                </div>
                <div className="notification-main-container">
                    <div className="notification-container">
                    {Object.keys(groupedNotifications).length > 0 ? (
                        Object.entries(groupedNotifications).map(([date, items]) => {
                        // Determine the label for the date
                        let dateLabel;
                        if (date === today) {
                            dateLabel = "Today";
                        } else if (date === yesterday) {
                            dateLabel = "Yesterday";
                        } else {
                            dateLabel = moment(date).format("DD-MMM-YYYY");
                        }

                        return (
                            <div key={date}>
                            <div className="notification-day">{dateLabel}</div>
                            <div className="notification-sub-container">
                                {items.map((item) => (
                                <NotificationBox
                                    key={item.id}
                                    image={categoryImages[item.category]}
                                    message={item.content}
                                    date={moment(item.timestamp).format("DD-MM-YY")}
                                />
                            ))}
                            </div>
                        </div>
                        );
                        })
                    ) : (
                    <p>No notifications available.</p>
                    )}
                </div>
                </div>
                <Pagination
                    currentPage={currentPage}
                    totalPages={totalPages}
                    onPageChange={setCurrentPage}
                />
            </div>
            <Footer />
        </div>
    );
    };

    export default Notifications;