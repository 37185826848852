import React, { useEffect, useState } from "react";
import './DateFilter.css';
import './DateFilterAR.css';

// Components
import PrimarySolidButton from "../Buttons/PrimarySolidButton/PrimarySolidButton";
import PrimaryMutedButton from "../Buttons/PrimaryMutedButton/PrimaryMutedButton";

const DateFilterAR = ({ onDateSelect }) => {
    const [open, setOpen] = useState(false);
    const [fromDate, setFromDate] = useState("");
    const [toDate, setToDate] = useState("");
    const [dateSelected,setDateSelected] = useState(false);

    const handleToggle = () => {
        setOpen((prev) => !prev);
    };

    const handleReset = () => {
        setFromDate("");
        setToDate("");
        onDateSelect({ fromDate: null, toDate: null });
        setOpen(false);
        setDateSelected(false);

    };

    const handleDateChange = () => {
        onDateSelect({ fromDate, toDate });
        setOpen(false);
        if(fromDate && toDate){
            setDateSelected(true)
        }
        else{
            setDateSelected(false)
        }
    };
return (
    <div className="date-filter-main">
        <div className={`date-filter-container date-filter-container-ar ${dateSelected?"date-selected":""}`} onClick={handleToggle}>
            <div className="filter-by-date-box-ar">
                <span>تصفية حسب</span>
                <span className={`date-text-filter ${dateSelected?"date-selected":""}`}>تاريخ</span> 
            </div>
            <span className={`arrow-icon ${open ? "open" : ""}`}>
                <svg className={`${dateSelected?"date-selected":""}`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
                    <path d="M201.4 137.4c12.5-12.5 32.8-12.5 45.3 0l160 160c12.5 12.5 12.5 32.8 0 45.3s-32.8 12.5-45.3 0L224 205.3 86.6 342.6c-12.5 12.5-32.8 12.5-45.3 0s-12.5-32.8 0-45.3l160-160z" />
                </svg>
            </span>
        </div>
    {open && (
        <div className="date-picker-container date-picker-container-ar">
            <div className="date-input-fields">
                <div className="input-box-container">
                    {!fromDate && <span className="date-placeholder">من التاريخ</span>}
                    <input
                        type="date"
                        id="from-date"
                        value={fromDate}
                        onChange={(e) => setFromDate(e.target.value)}
                        dir="rtl"
                        lang="ar"
                    />
                </div>
                <div className="input-box-container">
                    {!toDate && <span className="date-placeholder">حتى الآن</span>}
                    <input
                        type="date"
                        id="to-date"
                        value={toDate}
                        onChange={(e) => setToDate(e.target.value)}
                        dir="rtl"
                        lang="ar"
                    />
                </div>
            </div>
            <div className="button-section button-section-ar ">
                <PrimarySolidButton label={"يتقدم"} onClick={handleDateChange}/>
                <PrimaryMutedButton label={"إعادة ضبط"} onClick={handleReset}/>
            </div>
        </div>
    )}
    </div>
    );
};

export default DateFilterAR;