import React,{useState} from "react";
import './SortbyFilter.css'

const SortbyFilter = ({options,onSortSelect}) =>{

    const [sortOption, setSortOption] = useState(options[0]);
    const handleSortChange = (event) => {
            setSortOption(event.target.value);
            onSortSelect(event.target.value);
            };
    return(
        <div className="sortby-main">
            <div className="sort-filter-content">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 23 23" fill="none">
                    <path d="M2.875 15.3333L6.70833 19.1666L10.5417 15.3333" stroke="#666666" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M6.7085 19.1666V3.83325" stroke="#666666" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.5415 3.83325H20.1248" stroke="#666666" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.5415 7.66675H17.2498" stroke="#666666" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M10.5415 11.5H14.3748" stroke="#666666" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span className="filter-label">Sort By:</span>
                <select
                className="filter-select"
                value={sortOption}
                onChange={handleSortChange}
                >
                {options.map((option, index) => (
                    <option key={index} value={option}>
                    {option}
                    </option>
                ))}
                </select>
            </div>
        </div>
    )
}

export default SortbyFilter;