import React from "react";
import './AddressCard.css'

const AddressCard = ({value,readOnly="readOnly",onEdit,isArabic=false,isEditable=true})=>{
    return(
        <div className="card-container">
            <div className="address-section">
                <textarea name="address" id="address" value={value} readOnly={readOnly} className="booking-address">
                </textarea>
            </div>
            {isEditable &&
                <div className={`edit-pen ${isArabic?"edit-pen-ar":""}`} onClick={onEdit}>
                <svg xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 20 20" fill="none">
                    <g clip-path="url(#clip0_404_7108)">
                    <path d="M17.645 5.67658C18.0856 5.2361 18.3332 4.63863 18.3333 4.01562C18.3333 3.39261 18.0859 2.79508 17.6454 2.35449C17.205 1.9139 16.6075 1.66634 15.9845 1.66626C15.3615 1.66618 14.764 1.9136 14.3234 2.35408L3.20169 13.4782C3.00821 13.6712 2.86512 13.9087 2.78503 14.1699L1.68419 17.7966C1.66266 17.8686 1.66103 17.9452 1.67949 18.0181C1.69794 18.091 1.73579 18.1576 1.78902 18.2107C1.84225 18.2639 1.90888 18.3016 1.98183 18.32C2.05477 18.3383 2.13133 18.3366 2.20336 18.3149L5.83086 17.2149C6.09183 17.1355 6.32934 16.9933 6.52253 16.8007L17.645 5.67658Z" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M12.5 4.16675L15.8333 7.50008" stroke="black" stroke-width="1.66667" stroke-linecap="round" stroke-linejoin="round"/>
                    </g>
                    <defs>
                    <clipPath id="clip0_404_7108">
                    <rect width="20" height="20" fill="white"/>
                    </clipPath>
                    </defs>
                </svg>
                </div>
            }
        </div>
    )
}

export default AddressCard;