import React from "react";
import '../../../Common.css';
import '../Buttons.css';
import './PrimarySolidButton.css';

const PrimarySolidButton = ({label,onClick,disabled=false })=>{
    return (
        <div className={`btn primary-solid-btn ${disabled ? "disabled" : ""}`} 
            onClick={!disabled?onClick:null}>{label}
        </div>
    )
}


export default PrimarySolidButton;