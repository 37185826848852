import React,{useState} from "react";
import './SidebarFilter.css'
// Components
import SecondaryRadioTileInput from "../InputFields/RadioInputs/SecondaryRadioTileInput/SecondaryRadioTileInput";

const SidebarFilter = ({onFilterChange})=>{
    const [selectedValue, setSelectedValue] = useState("All");
    const handleSelection = (value) => {
        setSelectedValue(value);
        onFilterChange(value);
    };
    return(
        <div className="side-bar-filter-main">
            {["All", "Under Process", "Accepted","Rejected","Completed","Cancelled"
                ].map((filter) => {
                    const cd = filter .toLowerCase().replace(" ", "");
                    const id = filter //==="All" ? "":filter
                    return (
                        <SecondaryRadioTileInput
                            key={id}
                            content={filter}
                            name={"sidebar-filter"}
                            id={id}
                            htmlFor={id}
                            selectedValue={selectedValue}
                            onSelection={handleSelection}
                        />
                    );
                })}
        </div>
    )
}

export default SidebarFilter;