import React, { useEffect, useState } from "react";
import './BookingPopUp.css';
import moment from 'moment';

// components
import PrimarySolidButton from "../../Buttons/PrimarySolidButton/PrimarySolidButton";
import PrimaryMutedButton from "../../Buttons/PrimaryMutedButton/PrimaryMutedButton";
import PopUpMainHeading from "../../Heading/PopUpMainHeading/PopUpMainHeading";
import AccountWarning from "../TextItems/AccountWarning/AccountWarning";
import PopUpSubHeading from "../../Heading/PopUpSubHeading/PopUpSubHeading";
import InputTextField from "../../InputFields/InputTextField/InputTextField";
import PrimaryTextAreaField from "../../InputFields/TextAreaField/PrimaryTextField/PrimaryTextAreaField";

const BookingPopUpAR = ({data,onClose,onCancel,onEdit}) =>{
    const [address,setAddress] = useState();
    const [isEditable,setIsEditable] = useState(false);

    useEffect(()=>{
        const serviceAddress = [
            data.building || '', 
            data.street1 || '', 
            data.street2 || '', 
            data.city || '', 
            data.state || '', 
            data.country || '', 
            data.zip || ''
        ]
        .filter(part => part.trim() !== '') // Remove empty parts
        .join(', '); // Combine with a comma and space
    
        // Save the address to state
        setAddress(serviceAddress);

        if(data.request_status && data.request_status === "Under Process"){
            setIsEditable(true);
        }else{
            setIsEditable(false);
        }
    },[])
    const formatDate = (date) => {
            return moment(date).format("DD-MMM-YYYY");
    };
    
    const formatTime = (time) => {
        return moment(time, "HH:mm").format("hh A");
    };
    
    return (
        <div className="login-popup-overlay">
            <div className="register-pop-up">
                <div className="closeButton" onClick={onClose}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                    <path d="M21 7L7 21" stroke="#666666" stroke-linecap="round" stroke-linejoin="round"/>
                    <path d="M7 7L21 21" stroke="#666666" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                <div className="register-head-section">
                    <PopUpMainHeading content={"حجز خدمتي"} color={"blue"}/>
                </div>
                <form className="login-input-button-section">
                    <div className="input-field-section">
                        <InputTextField htmlFor={"created-date"} content={'تاريخ الإنشاء'} value={formatDate(data.created_time)} isArabic={true} dir="rtl"/>
                        <InputTextField htmlFor={"service-category"} content={'فئة الخدمة'} value={data.service.category.name} isArabic={true} dir="rtl" />
                        <InputTextField htmlFor={"service-name"} content={'اسم الخدمة'} value={data.service.name} isArabic={true} dir="rtl" />
                        <InputTextField htmlFor={"service-status"} content={'حالة الخدمة'} value={data.request_status} isArabic={true} dir="rtl" />
                        <PrimaryTextAreaField htmlFor={"location"} content={'موقع'} value={address} isArabic={true} dir="rtl" />
                        <InputTextField htmlFor={"scheduled-date"} content={'التاريخ والوقت المحددان'} value={`${formatDate(data.visit_date)}, ${formatTime(data.visit_time)}`} isArabic={true} dir="rtl" />
                    </div>
                    <div className="forgot-already-account-box margin-new new-box-style arabic-flex">
                        <PrimaryMutedButton onClick= {isEditable?onCancel:null} label={"إلغاء الطلب"} disabled={!isEditable}/>
                        <PrimarySolidButton label={"تحرير الطلب"} onClick={isEditable?onEdit:null} disabled={!isEditable}/>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default BookingPopUpAR;