import React from "react";
import '../../Common.css';
import './ServiceGridCard.css';
import { baseUrl } from "../../services/BaseUrl";

const ServiceGridCard = ({id,image,heading,content,onClick}) =>{
    return(
        <div className="service-card-main" id={id} onClick={onClick}>
            <div className="service-card-image-box">
                <img src={`${baseUrl}${image}`} alt="Service Image" />
            </div>
            <div className="service-card-texts">
                <div className="service-heading">
                    {heading}
                </div>
                <div className="service-content">
                    {content}
                </div>
            </div>
        </div>
    )
}

export default ServiceGridCard;