import React, { useContext, useEffect, useState } from "react";
import './ServiceBookings.css';
import './ServiceBookingsAR.css';
import '../../Common.css';
import { AuthContext } from "../../services/AuthContext";
import { ListBookings } from "../../services/Api";
import moment from 'moment';
import { CancelBooking } from "../../services/Api";
import { useNavigate } from "react-router-dom";
// Components
import NavBar from "../../components/NavBar/NavBar";
import NavBarMainAR from "../../components/NavBarMain/NavBarMainAR";
import FooterAR from "../../components/Footer/FooterAR";
import SidebarFilterAR from "../../components/SidebarFilter/SidebarFilterAR";
import SortbyFilterAR from "../../components/SortbyFilter/SortbyFilterAR";
import DateFilterAR from "../../components/DateFilter/DateFilterAR";
import ServiceListCard from "../../components/ServiceListCard/ServiceListCard";
import ServiceListCard2 from "../../components/ServiceListCard2/ServiceListCard2";
import BookingPopUpAR from "../../components/PopUps/BookingPopUp/BookingPopUpAR";
import Pagination from "../../components/Pagination/Pagination";
// images
import plumbing from '../../assets/plumbing.png';
import { renderToReadableStream } from "react-dom/server";


const ServiceBookingsAR = () =>{
    const {fetchWithAuth} = useContext(AuthContext)
    const sortOptions = ['Newest', 'Oldest'];
    const [sortValue,setSortValue] = useState("newest");
    const [bookings,setBookings] = useState([]);
    const [filter,setFilter] = useState("");
    const [popupData,setPopupData] = useState();
    const [isEditable,setIsEditable] = useState(false);
    const [isPopupOpen, setIsPopupOpen] = useState(false);
    const [bookingStatus, setBookingStatus] = useState();
    const [currentPage, setCurrentPage] = useState(1);
    const [totalPages, setTotalPages] = useState(1);
    const [selectedDates, setSelectedDates] = useState({ fromDate: null, toDate: null });
    const navigate = useNavigate();

    const formatDate = (date) => {
        return moment(date).format("DD-MMM-YYYY");
    };

    const handleDateSelection = (dates) => {
        console.log("Selected Dates:", dates);
        setSelectedDates(dates);
    };
    
    const formatTime = (time) => {
        return moment(time, "HH:mm").format("hh A");
    };
    const handleFilterChange = (value) =>{
        if(value==="All"){
            value = '';
        }
        setFilter(value)
    }
    const handleSortByValue = (value) =>{
        value = value.trim().toLowerCase();
        setSortValue(value);
    }
    const showBookingDetails =(booking) =>{
        setPopupData(booking)
        setIsPopupOpen(true);
        if(booking.request_status && booking.request_status === "Under Process"){
            setIsEditable(true);
        }
    }
    const closePopup = () => {
        setIsPopupOpen(false);
    };
    // useEffect(()=>{
    //     console.log(popupData,"hello")
    //     if(popupData.request_status && popupData.request_status === "Under Process"){
    //         setIsEditable(true);
    //     }
    // },[popupData])
    const cancelBooking = async()=>{
        const params = popupData.id
        try{
            const response = await fetchWithAuth(CancelBooking,{params})
            if(response.error){
                console.error("Error fetching profile:", response.error);
            }
            else{
                setIsPopupOpen(false);
                setBookingStatus("cancelled");
            }
        }
        catch(error){
            console.error("unexpected error",error);
        }
    }

    useEffect(() =>{
        const fetchMyBookings = async() =>{
            const from_date = selectedDates.fromDate
                    ? moment(selectedDates.fromDate).format("YYYY-MM-DD")
                    : "";
            const to_date = selectedDates.toDate
                    ? moment(selectedDates.toDate).format("YYYY-MM-DD")
                    : "";
            const params = `filter=${filter}&sort=${sortValue}&page=${currentPage}&from_date=${from_date}&to_date=${to_date}`
            try {
                const response = await fetchWithAuth(ListBookings,{params})
                if (response.error) {
                    console.error("Error fetching profile:", response.error);
                }
                else{
                    setBookings(response.data.data)
                    setTotalPages(10)

                }
            }
            catch (error) {
                console.error("Unexpected error:", error);
            }
        }
        fetchMyBookings();
    
    },[filter,sortValue,bookingStatus,currentPage,selectedDates])

    const editMyBooking = () =>{
        navigate(`/ar/edit-service-booking/${popupData.service.id}/`,{
            state:{
                bookingId:popupData.id,
                visit_date:popupData.visit_date,
                visit_time:popupData.visit_time
            },
        });
    };

    // useEffect(()=>{
    // },[bookings])
    return(
        <div className="body-container">
            <div>
                <NavBarMainAR/>
                <div className="page-title-box">
                    <div className="page-title">
                        حجز خدمتي
                    </div>
                </div>
                <div className="service-bookings-main-container">
                    <div className="service-bookings-sub-container arabic-flex">
                        <div className="service-booking-sidebar">
                            <SidebarFilterAR onFilterChange={handleFilterChange}/>
                        </div>
                        <div className="service-booking-right-section">
                            <div className="service-booking-filter-section service-booking-filter-section-ar">
                                <SortbyFilterAR options={sortOptions} onSortSelect = {handleSortByValue}/>
                                <DateFilterAR onDateSelect={handleDateSelection}/>

                                {/* <SortbyFilter options={sortOptions}/> */}
                            </div>
                            <div className="service-booking-list-section">
                            {   bookings.length>0 ? (
                                bookings.map((booking)=>(

                                    <ServiceListCard2 
                                    key={booking.id}
                                    image={booking.service.image}
                                    heading ={booking.service.name}
                                    subHeading={booking.service.description}
                                    hasButton={true}
                                    createdDate={formatDate(booking.created_time)}
                                    scheduledDate={formatDate(booking.visit_date)}
                                    scheduledTime={formatTime(booking.visit_time)}
                                    status={booking.request_status}
                                    onButtonClick={()=>showBookingDetails(booking)}
                                    />
                                )) 
                            ):('')
                            
                            }
                            {isPopupOpen && <BookingPopUpAR data={popupData} onClose={closePopup} 
                            onCancel={cancelBooking} onEdit={editMyBooking} isEditable={isEditable}/>}

                            </div>
                        </div>
                        
                    </div>
                    <Pagination
                        currentPage={currentPage}
                        totalPages={totalPages}
                        onPageChange={setCurrentPage}
                    />
                </div>
            </div>
            <FooterAR/>
        </div>
    )
}

export default ServiceBookingsAR;