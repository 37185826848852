import React from "react";
import './SecondaryRadioTileInput.css'


const SecondaryRadioTileInput = ({ id, name, htmlFor, content, selectedValue, onSelection,isarabic=false }) =>{
    const isSelected = selectedValue === id;

    return(
        <div className="radio-tile-input-container">
            <label
                className={`radio-tile-2 ${isSelected ? "selected" : "" }
                            ${isarabic?"radio-tile-2-ar":""}
                            `}
                htmlFor={htmlFor}
            >
                {content}
            </label>
            <input
                type="radio"
                id={id}
                name={name}
                value={id}
                onChange={() => onSelection(id)}
                hidden
            />
        </div>
    )
}

export default SecondaryRadioTileInput;