import React from "react";
import '../../../Common.css';
import './MainHeading.css';

const MainHeading = ({heading})=>{
    return (
        <div className="main-heading">{heading}</div>
    )
}

export default MainHeading;