import { createContext, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { RefreshAccessToken } from './Api';
import { LogOutUser } from './Api';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
    const [accessToken, setAccessToken] = useState(localStorage.getItem('authToken'));
    const [refreshToken, setRefreshToken] = useState(localStorage.getItem('refreshToken'));
    const [isAuthLoading, setIsAuthLoading] = useState(false); // Loading state
    const [language, setLanguage] = useState(localStorage.getItem('language') || 'en'); 
    const [loginTOBook,setLoginToBook] = useState(false);
    const [showLoginSuccessPopup, setShowLoginSuccessPopup] = useState(false);

    const navigate = useNavigate();


    const isAuthenticated = !!accessToken;

    // console.log(accessToken,refreshToken)

    const updateTokens = (newAccessToken, newRefreshToken) => {
        if (newAccessToken) {
            localStorage.setItem('authToken', newAccessToken);
            setAccessToken(newAccessToken);
        }
        if (newRefreshToken) {
            localStorage.setItem('refreshToken', newRefreshToken);
            setRefreshToken(newRefreshToken);
        }
    };



    const refreshAccessToken = async () => {
        setIsAuthLoading(true); // Start loading
        try {
            const body = {
                refresh: JSON.stringify(refreshToken)
            }
            const {data,error} = await RefreshAccessToken({body});

            if (error) {
                console.error("Error refreshing token:", error);
                logout();
                throw new Error("Failed to refresh token");
            }

            const newAccessToken = data?.access;
            updateTokens(newAccessToken, null);
            // setAccessToken(newAccessToken);  
            // localStorage.setItem("authToken", newAccessToken);

        } catch (error) {
            logout();
        } finally {
            setIsAuthLoading(false); // End loading
        }

    };

    const logout = () => {

        try{
            // const response = await fetchWithAuth(LogOutUser)
            // console.log(response)
            localStorage.removeItem('authToken');
            localStorage.removeItem('refreshToken');

            setAccessToken(null);
            setRefreshToken(null);
            if(language==='ar'){
                navigate('/ar/');
            }else{
                navigate('/');
            }
        }
        catch(error){
            console.error("error logging out user",error)
        }
    };

    const fetchWithAuth = async (apiFunction, args = {}, requireAuth = true) => {
            if (!requireAuth) {
                return apiFunction(args);
            }
        
            if (!accessToken) {
            navigate("/");
            throw new Error("User is not authenticated");
            }
        
            try {
            // Attach the Authorization header if needed
                const response = await apiFunction({
                    ...args,
                    headers: { Authorization: `Bearer ${accessToken}`, ...(args.headers || {}) },
                    
                });
                if (response.error?.status === 401 && refreshToken) {
                    await refreshAccessToken();
                    return apiFunction({
                    ...args,
                    headers: { Authorization: `Bearer ${accessToken}`, ...(args.headers || {}) },
                    });
                } else if (response.error?.status === 401) {
                    logout();
                    throw new Error("Session expired");
                }
            
                return response;

            } 
            catch (err) {
                console.error("Error during API call:", err);
                logout();
                throw err;
            }
        };

        useEffect(() => {
            const currentPath = window.location.pathname;
        
            if (language === 'ar') {
                if (!currentPath.startsWith('/ar')) {
                    navigate(`/ar${currentPath}`); // Redirect to Arabic version
                }
            } else {
                if (currentPath.startsWith('/ar')) {
                    navigate(currentPath.replace('/ar', '')); // Redirect to English version
                }
            }
        }, [language, navigate]);

    
    return (
        <AuthContext.Provider 
            value={{ accessToken, refreshAccessToken, logout, fetchWithAuth,
                isAuthenticated,isAuthLoading,fetchWithAuth,updateTokens,
            language,setLanguage,loginTOBook,setLoginToBook,showLoginSuccessPopup,
            setShowLoginSuccessPopup}}
        >
        {children}
        </AuthContext.Provider>
    );
};