import React from "react";
import '../../Common.css';
import './Footer.css';
import logo from '../../assets/logo.png';


const Footer = ()=>{
    return (
        <div className="footer-container">
            <div className="footer-inner-container">
                <div className="contact-container">
                    <div className="footer-logo-box">
                        <img src={logo} alt="Fraternity Logo" />
                    </div>
                    <div className="contact">
                        <div>
                            <div className="heading">Contact Us</div>
                            <div className="address">1234 Elm Street, Suite 567 Springfield, NY 12345 United States</div>
                        </div>
                        <div>
                            <div>Phone: +1 (555) 123-4567</div>
                            <div>Email: support@example.com</div>
                        </div>
                    </div>
                </div>
                <div className="terms-privacy-box">
                    <span>Terms and conditions</span>
                    <span>Privacy policy</span>
                </div>
            </div>
        </div>
    )
}

export default Footer;