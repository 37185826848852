import React from "react";
import './ServiceDetail.css';
import { useEffect,useState,useContext } from "react";
import { useNavigate,useParams } from "react-router-dom";
import { AuthContext } from "../../services/AuthContext";
// components
import NavBar from "../../components/NavBar/NavBar";
import NavBarMain from "../../components/NavBarMain/NavBarMain";
import Footer from "../../components/Footer/Footer";
import ServiceListCard from "../../components/ServiceListCard/ServiceListCard";
import { ServicesListView } from "../../services/Api";
import { SingleServiceCategoryAPI } from "../../services/Api";

// images
import plumbing from '../../assets/plumbing.png';

const ServiceDetailPage = () =>{
    const {id} = useParams();
    const [services, setServices] = useState([]);
    const [loading, setLoading] = useState(true);
    const [category,setCategory] = useState([]);
    const navigate = useNavigate();
    const { logout,isAuthenticated } = useContext(AuthContext);
    useEffect(() => {
        const fetchServices = async () => {
        const params = `category=${id}`
        try {
            const response = await ServicesListView({params});
            setServices(response.data.data);
        } 
        catch (error) {
            console.error("Error fetching services:", error);
        } 
        finally {
            setLoading(false);
        }
        };
    fetchServices();
    },[]);


    useEffect(() => {
        const fetchCategory = async () => {
        const params = `${id}`
        try {
            const response = await SingleServiceCategoryAPI({params});
            setCategory(response.data.data);
        } 
        catch (error) {
            console.error("Error fetching Category:", error);
        } 
        };
        fetchCategory();
    },[]);

    const handleServiceClick = (id)=>{
        navigate(`/book-service/${id}/`)
    }

    return(
        <div className="body-container">
            <div>
                {isAuthenticated?<NavBarMain/>:<NavBar/> }
                <div className="page-title-box">
                    <div className="page-title">
                    {category?.name ? `${category.name} Services` : "Services"}
                    </div>
                    <div className="page-sub-title">
                        Select Service
                    </div>
                </div>
                <div className="service-card-container">
                    <div className="service-card-box">
                        {services.map((service)=>(
                            <ServiceListCard 
                            id={service.id}
                            image={service.image}
                            heading ={service.name}
                            subHeading={service.description}
                            hasButton={true}
                            onClick={()=>handleServiceClick(service.id)}
                            />
                        ))}
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default ServiceDetailPage;