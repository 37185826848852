import React, { useState } from "react";
import './NotificationIcon.css';


const NotificationIcon = ({count,onClick})=>{
    return (
        <div className="notification-icon-container" onClick={onClick}>
            <div className="notification-svg">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 29 29" fill="none">
                <path d="M17.9523 20.6407C20.2036 20.3742 22.4157 19.8429 24.5425 19.0578C22.7414 17.0626 21.7461 14.4692 21.7501 11.7812V10.875C21.7501 8.95218 20.9862 7.10811 19.6266 5.74848C18.267 4.38884 16.4229 3.625 14.5001 3.625C12.5772 3.625 10.7332 4.38884 9.37354 5.74848C8.01391 7.10811 7.25007 8.95218 7.25007 10.875V11.7812C7.25371 14.4693 6.25801 17.0628 4.4564 19.0578C6.55044 19.8312 8.75807 20.3689 11.0479 20.6407M17.9523 20.6407C15.6588 20.9128 13.3413 20.9128 11.0479 20.6407M17.9523 20.6407C18.1264 21.1843 18.1697 21.7613 18.0787 22.3248C17.9876 22.8883 17.7648 23.4223 17.4283 23.8834C17.0919 24.3445 16.6513 24.7196 16.1424 24.9782C15.6336 25.2368 15.0709 25.3716 14.5001 25.3716C13.9293 25.3716 13.3666 25.2368 12.8577 24.9782C12.3489 24.7196 11.9083 24.3445 11.5718 23.8834C11.2354 23.4223 11.0125 22.8883 10.9215 22.3248C10.8304 21.7613 10.8737 21.1843 11.0479 20.6407M3.7749 9.0625C4.11562 7.0385 5.02407 5.15287 6.39457 3.625M22.6056 3.625C23.9761 5.15287 24.8845 7.0385 25.2252 9.0625" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            </div>
            {count > 0 && (
                <div className="notification-count">
                    {count}
                </div>
            )}
        </div>
    )
}

export default NotificationIcon;