import React from "react";
import './ServiceListCard.css'
import { baseUrl } from "../../services/BaseUrl";

// components
import PrimarySolidButton from "../Buttons/PrimarySolidButton/PrimarySolidButton";

const ServiceListCard = ({id,image,heading,subHeading,hasButton,onClick}) =>{
    
    return(
        <div className="service-list-card-main-container" id={id}>
            <div className="image-title-container">
                <div className="service-list-image-box">
                    <img src={`${baseUrl}${image}`} alt="Service Detail" />
                </div>
                <div className="service-list-detail-text">
                    <div className="service-detail-heading">
                        {heading}
                    </div>
                    <div className="service-detail-subheading">
                        {subHeading}
                    </div>
                </div>
            </div>
            {hasButton && <PrimarySolidButton label='Select' onClick={onClick}/>}
        </div>
    )
}

export default ServiceListCard;