import React from "react";
import './ServiceListCard.css'
import { baseUrl } from "../../services/BaseUrl";

// components
import PrimarySolidButton from "../Buttons/PrimarySolidButton/PrimarySolidButton";

const ServiceListCardAR = ({id,image,heading,subHeading,hasButton,onClick}) =>{
    
    return(
        <div className="service-list-card-main-container service-list-card-main-container-ar" id={id}>
            <div className="image-title-container image-title-container-ar">
                <div className="service-list-image-box">
                    <img src={`${baseUrl}${image}`} alt="Service Detail" />
                </div>
                <div className="service-list-detail-text service-list-detail-text-ar">
                    <div className="service-detail-heading">
                        {heading}
                    </div>
                    <div className="service-detail-subheading">
                        {subHeading}
                    </div>
                </div>
            </div>
            {hasButton && <PrimarySolidButton label='يختار' onClick={onClick}/>}
        </div>
    )
}

export default ServiceListCardAR;