
const LocationValidator = ({ fields }) => {
    let error = ""
    for (const key in fields) {
        if (fields[key].trim().length === 0) {
            error = `${key} can not be empty`
            return error;
        }
        else {
            error = ""
        }
    }
    return error
};


const LocationValidatorAR = ({ fields }) => {
    let error = ""
    for (const key in fields) {
        if (fields[key].trim().length === 0) {
            error = `لا يمكن أن تكون فارغة ${key}`
            return error;
        }
        else {
            error = ""
        }
    }
    return error
};

const getNextTime = ()=>{
    let now = new Date();
    let hours = now.getHours();
    hours  = parseInt(hours+1,10).toString();
    hours = hours>18?"18":hours
    return hours;
}

const isToday =(selectedDate) =>{
    
    const selectedDateObj = new Date(selectedDate);
    const today = new Date();
    const yesToday = selectedDateObj.getFullYear() === today.getFullYear() &&
                    selectedDateObj.getMonth() === today.getMonth() &&
                    selectedDateObj.getDate() === today.getDate();
    return yesToday
}
    

export  {LocationValidator,LocationValidatorAR,getNextTime,isToday};