import React,{useState,useEffect,useContext} from "react";
import '../Common.css';
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../services/AuthContext";

// Cpmponents
import NavBar from "../components/NavBar/NavBar";
import NavBarMain from "../components/NavBarMain/NavBarMain";
import Banner from "../components/Banner/Banner";
import MainHeading from "../components/Heading/MainHeading/MainHeading";
import CategoryAndSrviceTab from "../components/CategoryAndServiceTab/CategoryAndServiceTab";
import Footer from "../components/Footer/Footer";
import PrimarySolidButton from "../components/Buttons/PrimarySolidButton/PrimarySolidButton";
// import Login from "../components/PopUps/LogIn/Login";
// import CreateAccount from "../components/PopUps/CreateAccount/CreateAccount";
// import LocationForm2 from "../components/PopUps/LocationForm/LocationForm2";
// import OtpForm from "../components/PopUps/OtpForm/OtpForm";
// import BookingPopUp from "../components/PopUps/BookingPopUp/BookingPopUp";
// import ServiceBookedPopUp from "../components/PopUps/ServiceBookedPopUp/ServiceBookedPopUp";
// import ScuccessPopUp from "../components/PopUps/AccountCreationSuccess/ScuccessPopUp";
// import ForgotPassword from "../components/PopUps/ForgotPassword/ForgotPassword";
// import { responsiveFontSizes } from "@mui/material";

const Home = ()=>{
    // const [isLoginPopupOpen, setIsLoginPopupOpen] = useState(false);
    // const [isRegisterPopup,setIsRegisterPopup] = useState(false)
    // const [isLocationPopup,setIsLocationPopup] = useState(false);
    // const [isOtpPopup,setIsOtpPopup] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    // const [regSuccess,setRegSuccess] = useState(false);
    // const [resetSuccess,setResetSuccess] = useState(false);
    // const [forgotPass,setForgotPass] = useState(false);
    // const [verifyOptError,setVerifyError] = useState("");
    const [searchValue,setSearchValue] = useState("");
    const [selectedTab,setSelectedTab] = useState("categories");
    const { logout,isAuthenticated } = useContext(AuthContext);
    const navigate = useNavigate();

    // const [registerFormData, setRegisterFormData] = useState({
    //     firstname: "",
    //     lastname: "",
    //     mobile: "",
    //     email: "",
    //     createpass: "",
    //     confirmpass: "",
    //     country: "",
    //     state: "",
    //     street1: "",
    //     street2: "",
    //     building: "",
    //     city: "",
    //     zip: "",
    //     otp:"",
    // });

    // const [forgotPasswordData, setForgotPasswordData] = useState({
    //     otp:"",
    //     mobile: "",
    //     newPassword: "",
    //     confirmPassword: "",
    // });

    const handleViewAllClick = ()=>{
        // if(isLoggedIn){
        //     const tab = selectedTab; 
        //     navigate('/services', { state: { tab } });
        //     // navigate('/services');
        // }
        // else{
        //     setIsLoginPopupOpen(true); 
        // }
        const tab = selectedTab; 
        navigate('/services', { state: { tab } });
    }

    useEffect(() => {
        const checkAuthToken = () => {
            const accessToken = localStorage.getItem("authToken");
            const refreshToken = localStorage.getItem("refreshToken");
    
            if (accessToken && refreshToken) {
                setIsLoggedIn(true);
            } else {
                setIsLoggedIn(false);
            }
        };
        checkAuthToken();
    }, []);


    const handleStateOnLogout = () =>{
        setIsLoggedIn(false);
    }
    // const handleHaveAccount = () =>{
    //     setIsRegisterPopup(!isRegisterPopup);
    //     toggleLoginPopup();
    // }
    // const handleHaveAccountFromLocation = () =>{
    //     toggleLocationPopup();
    //     toggleLoginPopup();
    // }
    // const handleHaveAccountFromVerifyOtp = () =>{
    //     toggleOtpPopup();
    //     toggleLoginPopup();
    // }
    // const handleCreateAccount = () =>{
    //     handleHaveAccount();
    // }
    // const toggleLocationPopup = () =>{
    //     setIsLocationPopup(!isLocationPopup)
    // }
    // const toggleOtpPopup = () =>{
    //     setIsOtpPopup(!isOtpPopup);
    // }
    // const toggleRegSuccessPopup =() =>{
    //     setRegSuccess(!regSuccess)
    // }
    // const toggleForgotPassPopUp =() =>{
    //     setForgotPass(!forgotPass)
    // }
    // const toggleResetSuccessPopUP = () =>{
    //     setResetSuccess(!resetSuccess);
    // }

    // Forgot password Section
    // const handleForgotPasswordInputChange = (field, value) => {
    //     setForgotPasswordData((prevData) => ({
    //         ...prevData,
    //         [field]: value,
    //     }));
    // };

    // const openForgotPassordPopUp = () =>{
    //     toggleLoginPopup();
    //     toggleForgotPassPopUp();
    // }

    // const handleResetPassSuccess = () =>{
    //     toggleForgotPassPopUp();
    //     toggleResetSuccessPopUP();
    //     setTimeout(() => {
    //         setResetSuccess(false);
    //         toggleLoginPopup();
    //     }, 3000);
    // }

    // Section for saving  the Registration form datas
    // const handleRegisterInputChange = (field, value) => {
    //     setRegisterFormData((prevData) => ({
    //         ...prevData,
    //         [field]: value,
    //     }));
    // };

    // const handleRegisterSubmit = () => {
    //     const { createpass, confirmpass, email } = registerFormData;

    //     if (createpass !== confirmpass) {
    //         alert("Passwords do not match!");
    //         return;
    //     }

    //     const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    //     if (!emailRegex.test(email)) {
    //         alert("Invalid email format!");
    //         return;
    //     }

    //     console.log("Register Form Data:", registerFormData);
    //     toggleRegisterPopUp();
    //     toggleLocationPopup();

    // };

    // const handleLocationSubmit = async() =>{
    //     const body={
    //         "mobile":registerFormData.mobile
    //     }
    //     try{
    //         const response = await SendOTP({body})
    //         if (response.error){
    //             console.log("error sending otp")
    //             return;
    //         }
    //         else{
    //             if (response.data.resp_code === 1){
    //                 toggleOtpPopup();
    //                 toggleLocationPopup();
    //             }
    //             else{
    //                 console.error("error sending otp")
    //             }
    //         }
    //     }
    //     catch(error){
    //         console.log("error sending otp",error)
    //     }
        

    // }

    // const handleVerifyOtp = async() =>{
    //     const body = {
    //         "mobile":registerFormData.mobile,
    //         "otp":registerFormData.otp
    //     }

    //     try{
    //         const response = await VerifyOTP({body})
    //         if (response.error){
    //             console.log(response)
    //             setVerifyError("Otp doesn't match. Please try again.")
    //             return;
    //         }
    //         else{
    //             if (response.data.resp_code === 1){
    //                 setVerifyError("")
    //                 // Register the user
    //                 registerUser();
    //             }
    //             else{
    //                 setVerifyError("Otp doesn't match. Please try again.")
    //             }
    //         }
    //     }
    //     catch(error){
    //         console.log("error sending otp",error)
    //     }
    // }

    // const registerUser = async() =>{
    //     const body ={
    //         "first_name":registerFormData.firstname,
    //         "last_name":registerFormData.lastname,
    //         "mobile":registerFormData.mobile,
    //         "email":registerFormData.email,
    //         "password":registerFormData.confirmpass,
    //         "country":registerFormData.country,
    //         "state":registerFormData.state,
    //         "street1":registerFormData.street1,
    //         "street2":registerFormData.street2,
    //         "building":registerFormData.building,
    //         "city":registerFormData.city,
    //         "zip":registerFormData.zip
    //     }
    //     try{
    //         const response = await RegisterUser({body})
    //         if (response.error){
    //             console.log("error registering the user")
    //             // setVerifyError("Otp doesn't match. Please try again.")
    //             return;
    //         }
    //         else{
    //             console.log(response)
    //             if (response.data.resp_code === 1){
    //                 toggleOtpPopup();
    //                 toggleRegSuccessPopup();
    //                 setTimeout(() => {
    //                     setRegSuccess(false);
    //                     toggleLoginPopup();

    //                 }, 2000);
    //             }
    //             else{
    //                 // setVerifyError("Otp doesn't match. Please try again.")
    //                 console.log("error")
    //             }
    //         }
    //     }
    //     catch(error){
    //         console.log("error sending otp",error)
    //     }
    // }



    function submitSearch(value){
        setSearchValue(value);
        setSelectedTab("services")
    }   
    
    return(
        <div className="body-container">
            <div>
            {isAuthenticated?<NavBarMain/>:
            <NavBar/> 
            }
            <Banner onSubmit={submitSearch} />
            <div className="content-section">
                <MainHeading heading="Explore Our services"/>
                <div className="heading-description">
                    Explore our wide range of services, available at your fingertips. Fast, easy,
                    and reliable help when you need it!
                </div>

                <CategoryAndSrviceTab 
                    search={selectedTab === "categories" ? "" : searchValue} 
                    tab={selectedTab} 
                    onTabChange={setSelectedTab}
                    showCount={8}
                />
            </div>
            <div className="primary-button-box">
                <PrimarySolidButton label="View All" onClick={handleViewAllClick}/>
            </div>

                    {/* {isLoginPopupOpen && <Login onClose={toggleLoginPopup} onLoginSuccess={handleLoginSuccess}
                    onCreateAccnt={handleCreateAccount} onForgotPass={openForgotPassordPopUp} />}
                {isRegisterPopup &&  <CreateAccount 
                    onClose={toggleRegisterPopUp} onLoginNow={handleHaveAccount}
                    onInputChange={handleRegisterInputChange} onSubmit={handleRegisterSubmit}
                /> } 
                {isLocationPopup && 
                    <LocationForm2
                        onClose={toggleLocationPopup} 
                        onInputChange={handleRegisterInputChange} 
                        onSubmit={handleLocationSubmit} 
                        onLoginNow={handleHaveAccountFromLocation}
                    />
                }
                {isOtpPopup && 
                    <OtpForm 
                        error={verifyOptError} 
                        onSubmit={handleVerifyOtp} 
                        onInputChange={handleRegisterInputChange} 
                        onLoginNow={handleHaveAccountFromVerifyOtp}
                        onClose={toggleOtpPopup}
                    />
                }
                {showLoginSuccessPopup && <ScuccessPopUp message={"Welcome Back"}/>}
                {regSuccess && <ScuccessPopUp message={"Account created successfully"}/>}
                {resetSuccess && <ScuccessPopUp message={"Account reset successfully"}/>}
                {forgotPass && 
                    <ForgotPassword 
                        onClose={toggleForgotPassPopUp}
                        onInputChange={handleForgotPasswordInputChange}
                        onComplete = {handleResetPassSuccess}
                    />
                } */}
            </div>
            <Footer/>
        </div>
    )
}

export default Home;