import React from "react";
import './AccountWarning.css';

/*
    This component is for texts that have two different colors at the same line.
    for example: Don’t have an account? Create account now
    here 'Create account now' has a different color and it is a link.
*/

const AccountWarning = ({content,link,onClick}) =>{
    return(
        <div className="already-account-box">
            <span>{content}</span>
            <span onClick={onClick}>{link}</span>
        </div>
    )
}
export default AccountWarning;