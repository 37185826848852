import React, { useEffect, useState } from "react";
import './DateCalender.css';
import { LocalizationProvider } from "@mui/x-date-pickers";
import { DatePicker } from "@mui/x-date-pickers";
import { DateCalendar } from "@mui/x-date-pickers";
import { TextField } from "@mui/material";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";

const Calender = ({label="select date",onChange,mdate=dayjs()}) =>{

    const [selectedDate, setSelectedDate] = useState(mdate);

    const handleDateChange = (newValue) => {
        if (newValue) {
            setSelectedDate(newValue); 
            if (onChange) {
                onChange(newValue);
            }
        }
    };
    useEffect(() => {
        setSelectedDate(mdate); 
    }, [mdate]);

    return(
        <div className="card-container calender-new-padding">
            <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DateCalendar 
                defaultValue={mdate}
                value={selectedDate} 
                onChange={handleDateChange}
                minDate={dayjs()}
                // shouldDisableDate={disablePastDates}
                />
            </LocalizationProvider>
        </div>
    )
}

export default Calender;