import React, { useState,useContext } from "react";
import { useNavigate } from "react-router-dom";
import './Login.css';
import '../../../Common.css'
import { AuthContext } from "../../../services/AuthContext";
// components
import MobileInput from "../../InputFields/InputMobileField/InputMobileField";
import PasswordInput from "../../InputFields/InputPasswordField/InputPasswordField";
import PrimarySolidButton from "../../Buttons/PrimarySolidButton/PrimarySolidButton";
import PopUpMainHeading from "../../Heading/PopUpMainHeading/PopUpMainHeading";
import AccountWarning from "../TextItems/AccountWarning/AccountWarning";
import { TokenApi } from "../../../services/Api";

const Login = ({onClose,onLoginSuccess,onCreateAccnt,onForgotPass})=>{

    const { updateTokens } = useContext(AuthContext);
    const navigate = useNavigate();
    const [mobile,setMobile] = useState("");
    const [password,setPassword] = useState("");
    const [error,setError] = useState("");
    const handleOverlayClick = (e) =>{
        if(e.target.className === 'login-popup-overlay'){
            onClose();
        };
    }

    const handleLoginSubmit = async()=>{
        const body = {
            username: mobile, // Ensure this matches the key your API expects
            password: password,
        };
        try{
            const response =  await TokenApi({body})
            if (response.error){
                setError(response.error?.data?.detail || "Login failed. Please try again.");
            }else{
                const { refresh, access } = response.data
                // localStorage.setItem("authToken",access);
                // localStorage.setItem("refreshToken",refresh);
                updateTokens(access, refresh); 
                setError("");
                onLoginSuccess();
                onClose();
                // navigate("/");
            }
        }
        catch(err){
            setError(`An unexpected error occurred. Please try again later.`);
        }
    }
    return(
        <div className="login-popup-overlay" onClick={handleOverlayClick}>
            <div className="login-popup">
                <div className="login-head-section">
                    <PopUpMainHeading content={"Log in"}/>
                    <span>Welcome Back!</span>
                </div>
                <form className="login-input-button-section">
                    <div className="input-field-section">
                        <MobileInput htmlFor={"mobile"} id={"mobile"} 
                        content={"Enter mobile number"} value={mobile}																																																
                        onChange={(e) => setMobile(e.target.value)}/>
                        
                        <PasswordInput htmlFor={"password"} id={"password"}
                        content={"Enter  password"} value={password}
                        onChange={(e) => setPassword(e.target.value)}/>
                    </div>
                    {error && <div className="error-message">{error}</div>}
                    <PrimarySolidButton label={"Log in Now"} onClick={handleLoginSubmit}/>
                </form>
                <div className="forgot-already-account-box">
                    <AccountWarning content={"Don’t have an account?"}
                    link={"Create account now"} onClick={onCreateAccnt}/>
                    <span className="forgot-pass-text" onClick={onForgotPass}>Forgot password</span>
                </div>
            </div>
        </div>
    )
}

export default Login;