import React from "react";
import './CreateAccount.css';
import '../../../Common.css'

// components
import PrimarySolidButton from "../../Buttons/PrimarySolidButton/PrimarySolidButton";
import PopUpMainHeading from "../../Heading/PopUpMainHeading/PopUpMainHeading";
import AccountWarning from "../TextItems/AccountWarning/AccountWarning";
import PopUpSubHeading from "../../Heading/PopUpSubHeading/PopUpSubHeading";
import InputTextField from "../../InputFields/InputTextField/InputTextField";
import PasswordInput from "../../InputFields/InputPasswordField/InputPasswordField";
import ErrorMessage from "../../ErrorMessage/ErrorMessage";



const CreateAccount = ({onClose,onLoginNow,onInputChange, onSubmit,errorMessage}) =>{
    const handleOverlayClick = (e) =>{
        if(e.target.className === 'login-popup-overlay'){
            onClose();
        };
    }

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        onInputChange(name, value); // Pass input data to the parent component
    };

    return (
        <div className="login-popup-overlay" onClick={handleOverlayClick}>
            <div className="register-pop-up">
                <div className="register-head-section">
                    <PopUpMainHeading content={"Create your account"}/>
                    <PopUpSubHeading content={"Get started effortlessly in just a few simple steps!"}/>
                </div>
                
                <form className="login-input-button-section">
                    {errorMessage && <ErrorMessage message={errorMessage}/>}
                    <div className="input-field-section">
                        <div className="half-width-input gap-62">
                            <InputTextField htmlFor={"firstname"} content={'First name'} name={"firstname"}
                                onChange={handleInputChange}/>
                            <InputTextField htmlFor={"lastname"} content={'Last name'} name={"lastname"}
                                onChange={handleInputChange}/>
                        </div>
                        <InputTextField htmlFor={"mobile"} content={'Mobile'} name={"mobile"}
                            onChange={handleInputChange} type="mobile"/>
                        
                        <InputTextField htmlFor={"email"} content={'Email'} name={"email"}
                            onChange={handleInputChange}/>
                        
                        <div className="half-width-input gap-62">
                            <PasswordInput htmlFor={"createpass"} content={'Create a password'} name={"createpass"}
                                
                                onChange={handleInputChange} hasPhoneIcon={false}/>
                            <PasswordInput htmlFor={"confirmpass"} content={'Confirm password'} name={"confirmpass"}
                                
                                onChange={handleInputChange} hasPhoneIcon={false}/>
                        </div>
                    </div>
                    <div className="forgot-already-account-box margin-new">
                        <AccountWarning content={"Already have an account?"}
                        link={"Log in now"} onClick={onLoginNow}/>
                        <PrimarySolidButton label={"Continue"} onClick={onSubmit}/>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default CreateAccount;
