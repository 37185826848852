import React from "react";
import '../../Common.css'
import './BookService.css'
import { Navigate, useNavigate,useParams } from "react-router-dom";
import { useContext, useEffect,useState } from "react";
import { ServiceDetailAPI } from "../../services/Api";
import { AuthContext } from "../../services/AuthContext";
import { PersonalProfileAPI,CreateServiceRequest,EditLocation } from "../../services/Api";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from "dayjs";
import { getNextTime } from "../../services/Utilities";

// components
import Footer from "../../components/Footer/Footer";
import NavBar from "../../components/NavBar/NavBar";
import NavBarMain from "../../components/NavBarMain/NavBarMain";
import ServiceListCard from "../../components/ServiceListCard/ServiceListCard";
import AddressCard from "../../components/Cards/AddressCard/AddressCard";
import TimeCard from "../../components/Cards/TimeCard/TimeCard";
import Calender from "../../components/Cards/DatePicker/DateCalender";
import PrimarySolidButton from "../../components/Buttons/PrimarySolidButton/PrimarySolidButton";
import PrimaryMutedButton from "../../components/Buttons/PrimaryMutedButton/PrimaryMutedButton";
import ServiceBookedPopUp from "../../components/PopUps/ServiceBookedPopUp/ServiceBookedPopUp";
import LocationForm from "../../components/PopUps/LocationForm/LocationForm";
// images
import plumbing from '../../assets/plumbing.png';

const BookService = () =>{

    const {id} = useParams();
    const [service,setService] = useState([]);
    const { fetchWithAuth,isAuthenticated,setLoginToBook } = useContext(AuthContext);
    const [address, setAddress] = useState();
    const [selectedDate, setSelectedDate] = useState(dayjs());
    const [selectedTime,setSelectedTime] = useState(getNextTime());
    const [bookedText,setBookedText] = useState();
    const [isPopupOpen, setPopupOpen] = useState(false);
    const [isEditPopUp, setIsEditPopUp] = useState(false);
    const [popupData, setPopupData] = useState(null);
    const [isLoginPopupNeeded, setIsLoginPopupNeeded] = useState(false);
    const navigate = useNavigate();
    const [profileData, setProfileData] = useState({
            country: '',
            street1: '',
            street2: '',
            building: '',
            city: '',
            state: '',
            zip: ''
        });

    const handleDateChange = (date) => {
        setSelectedDate(date);
    }; 
    const handleTimeSelection = (time) => {
        // const formattedTime = time.toUpperCase();
        setSelectedTime(time);
    };
    useEffect(()=>{
        const formattedDate = selectedDate.format("DD-MMM-YYYY");
        const time = parseInt(selectedTime.trim(),10)
        const period = time < 12 ? "AM" : "PM";
        const formattedTime = time > 12 ? time - 12 : time;
        const displayTime = `${formattedTime} ${period}`;
        setBookedText(`Booked On: ${formattedDate} ${displayTime}`);
    },[selectedDate,selectedTime])

    useEffect(()=>{
        const fetchServiceDetail = async () =>{
            try{
                const response = await ServiceDetailAPI(id);
                setService(response.data.data);
            }
            catch (error) {
                console.error("Error fetching services:", error);
            } 
        };
        fetchServiceDetail();
    },[])

    useEffect(() => {
        if(!isAuthenticated){
            setProfileData({
                street1:'',
                street2:'',
                building:'',
                city:'',
                state:'',
                zip:'',
                country:'',
            });
            return
        }
        const fetchProfileData = async () => {
            try {
                // Replace ServiceCategoryAPI with the correct API function for customer/my-profile
                const response = await fetchWithAuth(PersonalProfileAPI); 
                
                if (response.error) {
                    console.error("Error fetching profile:", response.error);
                } else {
                    // const address = [
                    //     response.data.data.building || '', 
                    //     response.data.data.street1 || '', 
                    //     response.data.data.street2 || '', 
                    //     response.data.data.city || '', 
                    //     response.data.data.state || '', 
                    //     response.data.data.country || '', 
                    //     response.data.data.zip || ''
                    // ]
                    // .filter(part => part.trim() !== '') // Remove empty parts
                    // .join(', '); // Combine with a comma and space
    
                    // // Save the address to state
                    // setAddress(address);

                    setProfileData({
                        street1: response.data.data.street1 || '',
                        street2: response.data.data.street2 || '',
                        building: response.data.data.building || '',
                        city: response.data.data.city || '',
                        state: response.data.data.state || '',
                        zip: response.data.data.zip || '',
                        country:response.data.data.country || '',
                    });
                }
            } catch (error) {
                console.error("Unexpected error:", error);
            }
        };

        fetchProfileData();
    }, []);

    

    const convertToTimeFormat = (time) => {
        const hours = time < 10 ? `0${time}` : `${time}`;
        return `${hours}:00`;
    };

    const openPopup = (data) => {
        setPopupData(data); // Store data to be passed to the popup
        setPopupOpen(true); // Open the popup
    };
    const closePopup = () => {
        setPopupOpen(false);
    };
    const bookServiceFunction = async() =>{
        if (!isAuthenticated) {
            setLoginToBook(true);
            return;
        }
        try{
            const body = {
                "visit_date":selectedDate.format("YYYY-MM-DD"),
                "visit_time":convertToTimeFormat(selectedTime),
                "note":"Service Request",
                "state":profileData.state,
                "city":profileData.city,
                "street1":profileData.street1,
                "street2":profileData.street2,
                "building":profileData.building,
                "zip":profileData.zip,
                "service":service.id,
                "country":service.country

            }
            const response = await(fetchWithAuth(CreateServiceRequest,{body}))
            console.log(response.data)
            const {data,error} = response.data;
            if (data){
                openPopup(data);
            }else if(error){
                console.error("Error in service request:", error);
            }
        }
        catch (error) {
            console.error("Unexpected error:", error);
        }
        
    }
    const continueBooking = () =>{
        navigate('/services')
    }
    const cancelServiceBooking = () =>{
        navigate(-1)
    }
    const editAddress = (data) =>{
        setIsEditPopUp(true);
    }
    const closeEditForm = () =>{
        setIsEditPopUp(false);
    }
    const saveLocationDetails = async (updatedData) => {
        setProfileData({
            street1: updatedData.street1 || '',
            street2: updatedData.street2 || '',
            building: updatedData.building || '',
            city: updatedData.city || '',
            state: updatedData.state || '',
            zip: updatedData.zip || '',
            country:updatedData.country || '',
        });
        closeEditForm();
    }
    useEffect(()=>{
        const updateAddress = () =>{
            const address = [
                profileData.building || '', 
                profileData.street1 || '', 
                profileData.street2 || '', 
                profileData.city || '', 
                profileData.state || '', 
                profileData.country || '', 
                profileData.zip || ''
            ]
            .filter(part => part.trim() !== '') // Remove empty parts
            .join(', '); // Combine with a comma and space

            // Save the address to state
            setAddress(address);
        }
        updateAddress();
    },[profileData])



    return(
        <div className="body-container">
            <div>
                {isAuthenticated?<NavBarMain/>:<NavBar/> }
                <div className="page-title-box">
                    <div className="page-path">
                        <span>{service?.category?.name ? `${service.category.name} Service` : ""}</span>
                        <span>/</span>
                        <span>{service?.name?`${service?.name}` : ''}</span>
                    </div>
                    <div className="page-sub-title">
                        Book Your Service
                    </div>
                </div>
                <div className="service-card-container-2">
                    <div className="service-booking-sub-container">
                        <div>
                            <ServiceListCard 
                            image={service.image}
                            heading ={service.name}
                            subHeading={service.description}
                            hasButton={false}
                            />
                        </div>
                        <div className="booking-cards-section">
                            <div className="container-section">
                                <div className="card-title">Location</div>
                                {address && (
                                    <AddressCard 
                                    value={address}
                                    onEdit={()=>editAddress(profileData)}
                                    />
                                )}
                                {isEditPopUp && <LocationForm data={profileData} onClose={closeEditForm}
                                onSave={saveLocationDetails}/>}
                            </div>
                            <div className="container-section">
                                <div className="card-title">Choose Date</div>
                                <Calender onChange={handleDateChange} mdate={selectedDate}/>
                            </div>
                            <div className="container-section">
                                <div className="card-title">Select Time</div>
                                <TimeCard onSelection={handleTimeSelection}
                                currentSelection={selectedTime}
                                selectedDate = {selectedDate.format("DD-MMM-YYYY")}
                                />
                            </div>
                        </div>
                        <div className="confirm-button-section">
                            {bookedText && (
                                <div className="booked-date-text">
                                {bookedText}
                            </div>
                            )}
                            <div className="button-box">
                                <div className="cancel-button">
                                    <PrimaryMutedButton
                                        label={"Cancel"}
                                        onClick={cancelServiceBooking}
                                    />
                                </div>
                                <div className="confirm-button">
                                    <PrimarySolidButton 
                                        label={"Confirm Service Booking"}
                                        onClick={bookServiceFunction}
                                    />
                                    {isPopupOpen && <ServiceBookedPopUp data={popupData} onClose={closePopup} 
                                    onSubmit={continueBooking} />}
                                </div>
                            </div>
                        </div>
                    </div>
                    
                </div>
            </div>
            <Footer/>
        </div>
    )
}

export default BookService;