import React, { useState,useContext } from "react";
import { useNavigate } from "react-router-dom";
import './Login.css';
import '../../../Common.css'
import { AuthContext } from "../../../services/AuthContext";
// components
import MobileInput from "../../InputFields/InputMobileField/InputMobileField";
import PasswordInput from "../../InputFields/InputPasswordField/InputPasswordField";
import PrimarySolidButton from "../../Buttons/PrimarySolidButton/PrimarySolidButton";
import PopUpMainHeading from "../../Heading/PopUpMainHeading/PopUpMainHeading";
import AccountWarningAR from "../TextItems/AccountWarning/AccountWarningAR";
import { TokenApi } from "../../../services/Api";

const LoginAR = ({onClose,onLoginSuccess,onCreateAccnt,onForgotPass})=>{

    const { updateTokens } = useContext(AuthContext);
    const navigate = useNavigate();
    const [mobile,setMobile] = useState("");
    const [password,setPassword] = useState("");
    const [error,setError] = useState("");
    const handleOverlayClick = (e) =>{
        if(e.target.className === 'login-popup-overlay'){
            onClose();
        };
    }

    const handleLoginSubmit = async()=>{
        const body = {
            username: mobile, // Ensure this matches the key your API expects
            password: password,
        };
        try{
            const response =  await TokenApi({body})
            if (response.error){
                // setError(response.error?.data?.detail || "فشل تسجيل الدخول. يرجى المحاولة مرة أخرى.");
                setError("فشل تسجيل الدخول. يرجى المحاولة مرة أخرى.");
            }else{
                const { refresh, access } = response.data
                // localStorage.setItem("authToken",access);
                // localStorage.setItem("refreshToken",refresh);
                updateTokens(access, refresh); 
                setError("");
                onLoginSuccess();
                onClose();
                // navigate("/");
            }
        }
        catch(err){
            setError(`حدث خطأ غير متوقع. يرجى المحاولة مرة أخرى لاحقا.`);
        }
    }
    return(
        <div className="login-popup-overlay" onClick={handleOverlayClick}>
            <div className="login-popup">
                <div className="login-head-section">
                    <PopUpMainHeading content={"تسجيل الدخول"}/>
                    <span>مرحبًا بعودتك! دعونا نبدأ.</span>
                </div>
                <form className="login-input-button-section">
                    <div className="input-field-section">
                        <MobileInput htmlFor={"mobile"} id={"mobile"} 
                        content={"أدخل رقم الجوال"} value={mobile}																																																
                        onChange={(e) => setMobile(e.target.value)}
                        isArabic={true} dir="rtl"/>
                        
                        <PasswordInput htmlFor={"password"} id={"password"}
                        content={"أدخل كلمة المرور"} value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        isArabic={true} dir="rtl"/>
                    </div>
                    {error && <div className="error-message">{error}</div>}
                    <PrimarySolidButton label={"تسجيل الدخول الآن"} onClick={handleLoginSubmit}/>
                </form>
                <div className="forgot-already-account-box forgot-already-account-box-ar">
                    <AccountWarningAR content={"ليس لديك حساب؟"}
                    link={"قم بإنشاء حساب الآن"} onClick={onCreateAccnt}/>
                    <span className="forgot-pass-text" onClick={onForgotPass}>هل نسيت كلمة السر</span>
                </div>
            </div>
        </div>
    )
}

export default LoginAR;