import React from "react";
import '../../Common.css';
import './MyProfilePage.css';
// api and other functions
import { useContext,useEffect,useState } from "react";
import { AuthContext } from "../../services/AuthContext";
import { PersonalProfileAPI,UpdateProfileAPI } from "../../services/Api";
import { useNavigate } from "react-router-dom";
// Components
import NavBar from "../../components/NavBar/NavBar";
import NavBarMain from "../../components/NavBarMain/NavBarMain";
import Footer from "../../components/Footer/Footer";
import InputTextField from "../../components/InputFields/InputTextField/InputTextField";
import PrimaryMutedButton from "../../components/Buttons/PrimaryMutedButton/PrimaryMutedButton";
import PrimarySolidButton from "../../components/Buttons/PrimarySolidButton/PrimarySolidButton";
import UserIconAndName from "../../components/UserIconAndName/UserIconAndName";
import ForgotPassword2 from "../../components/PopUps/ForgotPassword/ForgotPassword2";


const MyProfilePage = () => {
    const { fetchWithAuth,logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const [ischangePass,setIsChangePass] = useState(false);
    const [isEditable, setIsEditable] = useState(false);
    const [profileData, setProfileData] = useState({
        firstName: '',
        lastName: '',
        mobile: '',
        email: '',
        country: '',
        street1: '',
        street2: '',
        buildingDetails: '',
        city: '',
        state: '',
        zipcode: ''
    });

    useEffect(() => {
        const fetchProfileData = async () => {
            try {
                const response = await fetchWithAuth(PersonalProfileAPI);
                if (response.error) {
                    console.error("Error fetching profile:", response.error);
                } else {
                    setProfileData({
                        firstName: response.data.data.user.first_name || '',
                        lastName: response.data.data.user.last_name || '',
                        mobile: response.data.data.user.mobile || '',
                        email: response.data.data.user.email || '',
                        country: response.data.data.country || '',
                        street1: response.data.data.street1 || '',
                        street2: response.data.data.street2 || '',
                        buildingDetails: response.data.data.building || '',
                        city: response.data.data.city || '',
                        state: response.data.data.state || '',
                        zipcode: response.data.data.zip || ''
                    });
                }
            } catch (error) {
                console.error("Unexpected error:", error);
            }
        };

        fetchProfileData();
    }, []);

    //  all the toggles
    const handleEditToggle = () => {
        setIsEditable(!isEditable);
    };

    const handleChangePassToggle = () =>{
        setIsChangePass(!ischangePass);
    };


    const handleSave = async () => {
        const body ={
            "user": {
                "first_name":profileData.firstName ,
                "last_name":profileData.lastName ,
                "mobile": profileData.mobile,
                "email":profileData.email 
            },
            "country": profileData.country,
            "state": profileData.state,
            "city": profileData.city,
            "street1": profileData.street1,
            "street2": profileData.street2,
            "building": profileData.buildingDetails,
            "zip": profileData.zip
        }
        try {
            const response = await fetchWithAuth(UpdateProfileAPI, {body})

            if (response.error) {
                console.error("Error updating profile:", response.error);
            } else {
                console.log("Profile updated successfully!");
                setIsEditable(false); // Disable editing mode
            }
        } catch (error) {
            console.error("Unexpected error while saving profile:", error);
        }
    };

    // Reset password section
    const openResetPassForm  = () =>{
        handleChangePassToggle();
    }
    const handleChangePassFormClose = () =>{
        setIsChangePass(false);
    }
    const handleSuccesChangePass = () =>{
        navigate("/");
        logout();
    }

    return (
        <div className="body-container">
            <div>
                <NavBarMain />
                <div className="my-profile-main-container">
                    <div className="my-profile-sub-container">
                        <span className="my-profile-heading">My Profile</span>
                        <div className="profile-cards-containers">
                            <div className="user-name-section">
                                <UserIconAndName 
                                    UserName={`${profileData.firstName} ${profileData.lastName}`}
                                />
                            </div>
                            <div className="input-fields-container">
                                <div className="half-width-input">
                                    <InputTextField
                                        htmlFor={"firstname"}
                                        content={"First Name"}
                                        value={profileData.firstName}
                                        onChange={(e) => setProfileData({ ...profileData, firstName: e.target.value })}
                                        readOnly={!isEditable}
                                    />
                                    <InputTextField
                                        htmlFor={"lastname"}
                                        content={"Last Name"}
                                        value={profileData.lastName}
                                        onChange={(e) => setProfileData({ ...profileData, lastName: e.target.value })}
                                        readOnly={!isEditable}
                                    />
                                </div>
                                <InputTextField
                                    htmlFor={"mobile"}
                                    content={"Mobile"}
                                    type={"number"}
                                    value={profileData.mobile}
                                    onChange={(e) => setProfileData({ ...profileData, mobile: e.target.value })}
                                    readOnly={!isEditable}
                                />
                                <InputTextField
                                    htmlFor={"email"}
                                    content={"Email"}
                                    type={"email"}
                                    value={profileData.email}
                                    onChange={(e) => setProfileData({ ...profileData, email: e.target.value })}
                                    readOnly={!isEditable}
                                />
                            </div>
                        </div>
                        <div className="profile-cards-containers">
                            <div className="input-fields-container">
                                <div className="profile-card-title">Location</div>
                                <InputTextField
                                    htmlFor={"country"}
                                    content={"Country"}
                                    value={profileData.country}
                                    onChange={(e) => setProfileData({ ...profileData, country: e.target.value })}
                                    readOnly={!isEditable}
                                />
                                <div className="half-width-input">
                                    <InputTextField
                                        htmlFor={"street1"}
                                        content={"Street 1"}
                                        value={profileData.street1}
                                        onChange={(e) => setProfileData({ ...profileData, street1: e.target.value })}
                                        readOnly={!isEditable}
                                    />
                                    <InputTextField
                                        htmlFor={"street2"}
                                        content={"Street 2"}
                                        value={profileData.street2}
                                        onChange={(e) => setProfileData({ ...profileData, street2: e.target.value })}
                                        readOnly={!isEditable}
                                    />
                                </div>
                                <InputTextField
                                    htmlFor={"builing-details"}
                                    content={"House/Building Number/Apartment"}
                                    value={profileData.buildingDetails}
                                    onChange={(e) => setProfileData({ ...profileData, buildingDetails: e.target.value })}
                                    readOnly={!isEditable}
                                />
                                <div className="half-width-input">
                                    <InputTextField
                                        htmlFor={"city"}
                                        content={"City"}
                                        value={profileData.city}
                                        onChange={(e) => setProfileData({ ...profileData, city: e.target.value })}
                                        readOnly={!isEditable}
                                    />
                                    <InputTextField
                                        htmlFor={"state"}
                                        content={"State/Province/Region"}
                                        value={profileData.state}
                                        onChange={(e) => setProfileData({ ...profileData, state: e.target.value })}
                                        readOnly={!isEditable}
                                    />
                                </div>
                                <InputTextField
                                    htmlFor={"zipcode"}
                                    content={"Zip code"}
                                    value={profileData.zipcode}
                                    onChange={(e) => setProfileData({ ...profileData, zipcode: e.target.value })}
                                    readOnly={!isEditable}
                                />
                            </div>
                        </div>
                        <div className="save-profile-button-containers">
                            <PrimaryMutedButton
                                label={"Change password"}
                                onClick={openResetPassForm}
                            />
                            <PrimarySolidButton
                                label={isEditable ? "Save" : "Edit Profile"}
                                onClick={isEditable ? handleSave : handleEditToggle}
                            />
                        </div>
                    </div>
                    { ischangePass && 
                        <ForgotPassword2 
                            onClose={handleChangePassFormClose}
                            onComplete={handleSuccesChangePass}
                        />
                    }
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default MyProfilePage;

