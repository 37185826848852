import React from "react";
import './OtpForm.css';

// Components
import PrimarySolidButton from "../../Buttons/PrimarySolidButton/PrimarySolidButton";
import PopUpMainHeading from "../../Heading/PopUpMainHeading/PopUpMainHeading";
import AccountWarning from "../TextItems/AccountWarning/AccountWarning";
import PopUpSubHeading from "../../Heading/PopUpSubHeading/PopUpSubHeading";
import InputTextField from "../../InputFields/InputTextField/InputTextField";



const OtpForm = ({onSubmit,onInputChange,error,onLoginNow,onClose}) =>{

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        onInputChange(name, value);
    };

    const handleOverlayClick = (e) => {
        if (e.target.className === "login-popup-overlay") {
            onClose();
        }
    };

    return(
        <div className="login-popup-overlay" onClick={handleOverlayClick}>
            <div className="register-pop-up">
                <div className="register-head-section">
                    <PopUpMainHeading content={"Enter OTP"}/>
                    <PopUpSubHeading content={"Please Enter the OTP sent to your phone to verify your identity."}/>
                </div>
                {error && <div className="error-message">{error}</div>}

                <form className="login-input-button-section">
                    <div className="input-field-section">
                        <InputTextField htmlFor={"otp"} name={"otp"} onChange={handleInputChange} content={'Enter OTP here'}/>
                    </div>
                    <div className="forgot-already-account-box margin-new">
                        <AccountWarning content={"Already have an account?"}
                        link={"Log in now"} onClick={onLoginNow}/>
                        <PrimarySolidButton label={"Continue"} onClick={onSubmit}/>
                    </div>
                </form>
            </div>
        </div>
    )
}

export default OtpForm;