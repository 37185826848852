import React,{useState} from "react";
import './CreateAccount.css';
import '../../../Common.css';
import { SendOTP,ResetPassword } from "../../../services/Api";

// components
import PrimarySolidButton from "../../Buttons/PrimarySolidButton/PrimarySolidButton";
import PopUpMainHeading from "../../Heading/PopUpMainHeading/PopUpMainHeading";
import AccountWarning from "../TextItems/AccountWarning/AccountWarning";
import PopUpSubHeading from "../../Heading/PopUpSubHeading/PopUpSubHeading";
import InputTextField from "../../InputFields/InputTextField/InputTextField";
import ScuccessPopUp from "../AccountCreationSuccess/ScuccessPopUp";
import PasswordInput from "../../InputFields/InputPasswordField/InputPasswordField";




const ForgotPassword2AR = ({ onClose,onSubmit,onComplete }) => {
    const [step, setStep] = useState(1); 
    const [otpError, setOtpError] = useState(""); 
    const [mobileError,setMobileError] = useState("");
    const [formData, setFormData] = useState({
        mobile: "",
        otp: "",
        newPassword: "",
        confirmPassword: "",
    });

    const handleOverlayClick = (e) => {
        if (e.target.className === "login-popup-overlay") {
            onClose();
        }
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const handleSendOtp = async () => {
        
        if (!formData.mobile) {
            setMobileError("الرجاء إدخال رقم جوال صالح");
            // alert("Please enter a valid mobile number");
            return;
        }
        const body={
            "mobile":formData.mobile
        }
        try {
            const response = await SendOTP({ body });
            if (response.error){
                setMobileError("error sending otp");
                // console.log("error sending otp")
                return;
            }
            else{
                if (response.data.resp_code === 1){
                    setStep(2);
                    setMobileError("");
                }
                else{
                    setMobileError("حدث خطأ أثناء إرسال كلمة المرور لمرة واحدة (OTP). يرجى المحاولة مرة أخرى.");
                }
            }
            
        } catch (error) {
            setMobileError("حدث خطأ ما. يرجى المحاولة مرة أخرى.");
        }
    };

    const handleResetAccount = async () => {
        if (!formData.otp) {
                setOtpError("الرجاء إدخال كلمة المرور لمرة واحدة.");
            return;
        }
        const body = {
            "mobile":formData.mobile,
            "otp":formData.otp,
            "password":formData.newPassword,
            "confirm_password":formData.confirmPassword
        }
        const { newPassword, confirmPassword } = formData;
        if (newPassword !== confirmPassword) {
            setOtpError("كلمات المرور غير متطابقة.")
            return;
        }
        try {
            const response = await ResetPassword({ body });
            if (response.error){
                setOtpError("كلمة مرور OTP غير متطابقة. يرجى المحاولة مرة أخرى.")
                return;
            }
            else{
                if (response.data.resp_code === 1){
                    setOtpError("")
                    onComplete();
                }
                else{
                    setOtpError("كلمة مرور OTP غير متطابقة. يرجى المحاولة مرة أخرى.")
                }
            }
            
        } catch (error) {
            console.error("Error verifying OTP:", error);
            setOtpError("حدث خطأ ما. يرجى المحاولة مرة أخرى.");
        }
    };


    return (
    <div className="login-popup-overlay" onClick={handleOverlayClick}>
        <div className="register-pop-up">
            <div className="register-head-section">
            <PopUpMainHeading content={"إعادة تعيين كلمة مرور حسابك"} />
            <PopUpSubHeading
                content={
                step === 1
                    ? "أدخل رقم هاتفك المحمول المسجل لتلقي كلمة مرور لمرة واحدة (OTP)."
                    : "أدخل بيانات الاعتماد التالية لإعادة ضبط حسابك."
                    // ? "Enter the OTP sent to your registered mobile number."
                    // : "Set your new password."
                }
            />
        </div>
        <form className="login-input-button-section">
            {step === 1 && (
            <div className="input-field-section">
                <InputTextField
                    htmlFor={"mobile"}
                    content={"الجوال المسجل"}
                    name={"mobile"}
                    value={formData.mobile}
                    onChange={handleChange}
                    isArabic={true} dir="rtl"
                />
                {mobileError && <p className="error-message">{mobileError}</p>}
                <PrimarySolidButton label={"أرسل كلمة مرور لمرة واحدة"} onClick={handleSendOtp} />
            </div>
            )}
            {step === 2 && (
            <div className="input-field-section">
                {otpError && <p className="error-message">{otpError}</p>}
                <InputTextField
                    htmlFor={"otp"}
                    content={"أدخل كلمة المرور لمرة واحدة"}
                    name={"otp"}
                    value={formData.otp}
                    onChange={handleChange}
                    isArabic={true} dir="rtl"
                />
                <PasswordInput
                    htmlFor={"newPassword"}
                    content={"كلمة المرور الجديدة"}
                    name={"newPassword"}
                    value={formData.newPassword}
                    onChange={handleChange}
                    type="password"
                    isArabic={true} dir="rtl"
                    hasPhoneIcon={false}
                />
                <PasswordInput
                    htmlFor={"confirmPassword"}
                    content={"تأكيد كلمة المرور"}
                    name={"confirmPassword"}
                    value={formData.confirmPassword}
                    onChange={handleChange}
                    type="password"
                    isArabic={true} dir="rtl"
                    hasPhoneIcon={false}
                />
                <PrimarySolidButton label={"يُقدِّم"} onClick={handleResetAccount} />
            </div>
            )}
        </form>
        </div>
    </div>
    );
};

export default ForgotPassword2AR;
