import React,{useState, useEffect, useContext} from "react";
import '../../Common.css';
import './CategoryAndSrviceTab.css'
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../services/AuthContext";
// Components
import CategoryGridCard from "../CategoryGridCard/CategoryGridCard";
import ServiceGridCard from "../ServicesGridCard/ServiceGridCard";
import PrimarySolidButton from "../Buttons/PrimarySolidButton/PrimarySolidButton";
import {ServiceCategoryAPI,ServiceAPI} from "../../services/Api";
import { baseUrl } from "../../services/BaseUrl";
// image(static)
import banner_image from '../../assets/banner_image.png';



const CategoryAndSrviceTabAR = ({activeClassName='active',search,tab="categories",onTabChange,onUnauthenticated,showCount=null})=>{
    const [services,setServices] = useState([]);
    const [selectedTab,setSelectedTab] = useState(tab)
    const [searchValue,setSearchValue] = useState("");
    const [searchTrigger, setSearchTrigger] = useState(0);
    const navigate = useNavigate();
    const {isAuthenticated} = useContext(AuthContext);

    useEffect(()=>{
        // setSearchValue(search);
        setSelectedTab(tab)
        setSearchTrigger(prev => prev + 1);
    },[search,searchValue])

    const fetchData = async (tabName)=>{
        const search = searchValue;
        let response;
        if(tabName === 'categories'){
            response =  await ServiceCategoryAPI({search})   
            // setServices(response.data.data)
        }
        else{
            const body = {}
            response =  await ServiceAPI({body,search})   
            // setServices(response.data.data)
        }

        if (showCount){
            setServices(response.data.data.slice(0,showCount))
        }else{
            setServices(response.data.data)
        }
    }

    useEffect(()=>{
        setSearchValue(search);
        fetchData(selectedTab);
    },[selectedTab,searchValue,searchTrigger]) //searchTrigger is removed from here

    const handleTabSwitch = (tabName) => {
        setSelectedTab(tabName);
        onTabChange(tabName)
    };

    const handleCategoryClick = (id) =>{
        // if(!isAuthenticated){
        //     onUnauthenticated();
        //     return;
        // }
        navigate(`/ar/select-service/${id}`)
    }
    const handleServiceClick = (id) =>{
        // if(!isAuthenticated){
        //     onUnauthenticated();
        //     return;
        // }
        navigate(`/ar/book-service/${id}`)
    }

    return (
        <div className="tab-container">
            <div className="tab-switch-box">
                <label className={`tab ${selectedTab === 'categories' ? activeClassName:''}`} htmlFor="categoryTab" >فئات</label>
                <input type="radio" id="categoryTab" 
                    name="categoryOrService" 
                    onChange={()=>handleTabSwitch('categories')} 
                    checked={selectedTab === 'categories'}
                    hidden
                />
                <label className={`tab ${selectedTab === 'services' ? activeClassName :''}`} htmlFor="serviceTab" >جميع الخدمات</label>
                <input type="radio" id="serviceTab" 
                    name="categoryOrService" 
                    onChange={()=>handleTabSwitch('services')} 
                    checked = {selectedTab === 'services'}
                    hidden
                />
            </div>
            <div className="tab-grid-card-container">
                {selectedTab === 'categories' ? (
                    <>
                        {services.length > 0 ? (
                            services.map((category) => (
                                <CategoryGridCard
                                    key={category.id} // Add a key for React list rendering
                                    id={category.id}
                                    name={category.name}
                                    count={category.service_count}
                                    image={category.image}
                                    onClick={() => handleCategoryClick(category.id)}
                                />
                            ))
                        ) : (
                            <div className="no-data-message">لم يتم العثور على فئات لبحثك.</div>
                        )}
                    </>
                ) : (
                    <>
                        {services.length > 0 ? (
                            services.map((service) => (
                                <ServiceGridCard
                                    key={service.id} // Add a key for React list rendering
                                    id={service.id}
                                    image={service.image}
                                    heading={service.name}
                                    content={service.description}
                                    onClick={() => handleServiceClick(service.id)}
                                />
                            ))
                        ) : (
                            <div className="no-data-message">لم يتم العثور على خدمات لبحثك.</div>
                        )}
                    </>
                )}
            </div>
        </div>
    )
}

export default CategoryAndSrviceTabAR;