import React, { useState } from "react";
import '../../Common.css';
import './Banner.css'
import banner_image from '../../assets/banner_image.png';
// components
import PrimarySearchBox from "../Search/PrimarySearch/PrimarySearch";



const Banner = ({onSubmit})=>{
    const [searchValue,setSearchValue]  = useState("")

    const handleInputChange = (e) => {
        const value  = e.target.value;
        setSearchValue(value);
    };

    const submitSearch = () =>{
        onSubmit(searchValue);
    }

    return (
        <div className="banner-outer-container">
            <div className="banner-inner-container">
                <div className="banner-text-container-left">
                    <div className="banner-texts">
                        <div className="banner-text-main-heading">
                            <span>Simplifying Service Bookings</span>
                            <span>Fast, Easy, Reliable!</span>
                        </div>
                        <div className="banner-text-sub-heading">
                            <span>
                                Easily book reliable services for any task you need—be it home repairs, cleaning,
                                or personal care. Convenient, fast, and trusted professionals available whenever 
                                you need them.
                            </span>
                        </div>
                    </div>
                    <div className="search-box">
                        <PrimarySearchBox onSearch={submitSearch}  htmlFor={"home_search"} onChange={handleInputChange} name={"home_search"}/>
                    </div>
                    <div className="feature-container">
                        <div className="feature-box">
                            <div className="svgicon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 51" fill="none">
                                    <path d="M42.5 27.625C42.5 38.25 35.0625 43.5625 26.2225 46.6438C25.7596 46.8006 25.2568 46.7931 24.7987 46.6225C15.9375 43.5625 8.5 38.25 8.5 27.625V12.75C8.5 12.1864 8.72388 11.6459 9.1224 11.2474C9.52091 10.8489 10.0614 10.625 10.625 10.625C14.875 10.625 20.1875 8.07499 23.885 4.84499C24.3352 4.46037 24.9079 4.24904 25.5 4.24904C26.0921 4.24904 26.6648 4.46037 27.115 4.84499C30.8337 8.09624 36.125 10.625 40.375 10.625C40.9386 10.625 41.4791 10.8489 41.8776 11.2474C42.2761 11.6459 42.5 12.1864 42.5 12.75V27.625Z" stroke="white" stroke-opacity="0.7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M25.5 17V25.5" stroke="white" stroke-opacity="0.7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M25.5 34H25.5146" stroke="white" stroke-opacity="0.7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <div className="feature-text">
                                <span>1000+</span>
                                <span>Verified Providers</span>
                            </div>
                        </div>

                        <div className="feature-box">
                            <div className="svgicon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 51" fill="none">
                                    <path d="M23.375 25.5H27.625C28.7522 25.5 29.8332 25.0522 30.6302 24.2552C31.4272 23.4582 31.875 22.3772 31.875 21.25C31.875 20.1228 31.4272 19.0418 30.6302 18.2448C29.8332 17.4478 28.7522 17 27.625 17H21.25C19.975 17 18.9125 17.425 18.275 18.275L6.375 29.75" stroke="white" stroke-opacity="0.7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M14.875 38.25L18.275 35.275C18.9125 34.425 19.975 34 21.25 34H29.75C32.0875 34 34.2125 33.15 35.7 31.45L45.475 22.1C46.295 21.3251 46.7736 20.2561 46.8055 19.1283C46.8374 18.0005 46.4199 16.9063 45.645 16.0862C44.8701 15.2662 43.8011 14.7876 42.6733 14.7558C41.5455 14.7239 40.4513 15.1413 39.6312 15.9162L30.7062 24.2037" stroke="white" stroke-opacity="0.7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M4.25 27.625L17 40.375" stroke="white" stroke-opacity="0.7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <div className="feature-text">
                                <span>1000+</span>
                                <span>Services</span>
                            </div>
                        </div>

                        <div className="feature-box">
                            <div className="svgicon">
                                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 51 51" fill="none">
                                    <path d="M46.327 21.25C47.2975 26.0128 46.6059 30.9643 44.3675 35.2788C42.1291 39.5933 38.4792 43.0101 34.0265 44.9593C29.5738 46.9085 24.5875 47.2723 19.899 45.99C15.2106 44.7078 11.1034 41.857 8.26249 37.913C5.42155 33.9691 4.01854 29.1704 4.28744 24.3172C4.55634 19.464 6.4809 14.8497 9.74016 11.2437C12.9994 7.63778 17.3964 5.25816 22.1978 4.50171C26.9992 3.74525 31.9148 4.6577 36.1249 7.08687" stroke="white" stroke-opacity="0.7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                    <path d="M19.125 23.375L25.5 29.75L46.75 8.5" stroke="white" stroke-opacity="0.7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                            </div>
                            <div className="feature-text">
                                <span>1000+</span>
                                <span>Services Done</span>
                            </div>
                        </div>

                    </div>
                </div>

                <div className="banner-image-section">
                    <div className="outer-circle-image">
                        <div className="inner-cicle-image">
                            <img src={banner_image} alt="Service Booking" className="banner-image" />
                        </div>
                    </div>
                </div>
                
            </div>
        </div>
    )
}


export default Banner