import React from "react";
import './ScuccessPopUp.css';
// images
import glitter from '../../../assets/glitter.gif'
import doticon from'../../../assets/dot-icon.gif'


const ScuccessPopUp = ({message}) =>{
    return (
        <div className="login-popup-overlay">
            <div className="register-pop-up success-popup">
                <div className="success-message">
                    <div className="dot-icon-image-box">
                        <img src={doticon} alt="success"/>
                        <img className="glitter-img" src={glitter} alt=""/>
                    </div>
                        
                    <div className="message-box">
                        {message}
                    </div>
                </div>
            </div>
        </div>
    )
}
export default ScuccessPopUp;