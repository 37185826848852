import React from "react";
import './UserIconAndName.css';

const UserIconAndName = ({UserName,direction='normal',place='normal'}) =>{
    const firstLetter = UserName ? UserName.charAt(0).toUpperCase() : 'A';
    const username = UserName 
        ? UserName.split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') 
        : '';
    return(
        <div className={`user-name-main-box ${direction === 'reverse' ? 'row-reverse' : ''}`}>
            <div className={`user-icon-box ${place==='nav' ? 'nav-bar-icon':''}`}>
                <span className={`user-first-letter ${place==='nav' ? 'nav-bar-icon-fl' :''}`}>
                    {firstLetter}
                </span>
            </div>
            <div className="user-name-box">
                {username}
            </div>
        </div>
    )
}

export default UserIconAndName;