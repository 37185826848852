import axios from 'axios'

const ApiConfig = async (method,url,headers=null,body=null)=>{
    const reqConfig = {
        method,
        url,
        data:body,
        headers:headers || { 'Content-Type': 'application/json' }
    };
    try{

        const response = await axios(reqConfig);
        return {'data':response.data,'error':null};
    }
    catch(error){
        return {'data':null,'error':error.response || error.message};
    }
    
};



export default ApiConfig;